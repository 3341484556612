import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GrStatusGood } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { Button, Loader, Toast } from "../../../../core/components/layout";
import { InputText } from "../../../../core/components/form";
import { useTranslation } from "react-i18next";
import {
  IsPendingRequest,
  OnSetSelectedDocuments,
  OnSetShowModalDocumentIngestion,
  OnSetShowModalDocumentDescription,
  OnSetShowModalDocumentDeletion,
  OnSetShowFilterSidebar,
  Filters,
  OnSetFilters,
  OnDownloadDocument,
  NamespacesData,
} from "../../types";
import { IoSearch } from "react-icons/io5";
import { BsFileEarmarkPlus } from "react-icons/bs";
import { FaListUl } from "react-icons/fa6";
import { FiFilter } from "react-icons/fi";
import { FaFilter } from "react-icons/fa";
import DocumentsTable from "./DocumentsTable";
import { TableData } from "./DocumentsType";
import CustomModal from "../../../../commons/CustomModal";
import { deleteDocuments } from "../../sources/services";
import { Grid } from "@mui/material";
import useDebounce from "../../../../hooks/useDebounce";
import { useAppDispatch } from "../../../../store/app.hooks";
import {
  removeDocuments,
  setRequestError,
  setSelectedDocuments,
} from "../../sources/documents.reducer";
import StickyFooter from "../../../../commons/StickyFooter";

interface TableDocumentsProps {
  isPendingRequest: IsPendingRequest;
  filters: Filters;
  selectedDocuments: TableData[];
  namespacesData: NamespacesData;
  onSetFilters: OnSetFilters;
  onSetSelectedDocuments: OnSetSelectedDocuments;
  onSetShowModalDocumentIngestion: OnSetShowModalDocumentIngestion;
  onSetShowFilterSidebar: OnSetShowFilterSidebar;
  onSetShowModalDocumentDescription: OnSetShowModalDocumentDescription;
  onSetShowModalDocumentDeletion: OnSetShowModalDocumentDeletion;
  onDownloadDocument: OnDownloadDocument;
}

const TableDocumentsComponent: React.FC<TableDocumentsProps> = ({
  isPendingRequest,
  filters,
  selectedDocuments,
  namespacesData,
  onSetFilters,
  onSetShowModalDocumentIngestion,
  onSetShowFilterSidebar,
  onSetShowModalDocumentDescription,
  onSetShowModalDocumentDeletion,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resetTableParams, setResetTableParams] = useState<number>(0);
  const [batchDeleteModal, setBatchDeleteModal] = useState<boolean>(false);
  const [batchDeleteError, setBatchDeleteError] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const debouncedValue = useDebounce<string>(searchText, 500);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const batchDelete = useCallback(() => {
    setBatchDeleteModal(true);
  }, []);

  const selectedDocumentIds: number[] = selectedDocuments.map(
    (item) => item._id
  );

  const confirmedBatchDelete = async () => {
    setIsLoading(true);
    dispatch(removeDocuments(selectedDocumentIds));
    setBatchDeleteModal(false);
    let notDeleted: TableData[] = [];
    for (const item of selectedDocuments) {
      let resp = await deleteDocuments({
        title: item.title,
        _id_namespace: item._id_namespace,
      });
      if (resp.code !== undefined) {
        notDeleted.push(item);
        dispatch(setRequestError(resp));
      }
    }
    if (notDeleted.length > 0) {
      setErrors(notDeleted.map((item) => item.title));
      setBatchDeleteError(true);
    }
    // some documents were deleted
    if (notDeleted.length < selectedDocuments.length) {
      setResetTableParams((prevValue) => prevValue + 1);
      Toast("success", t("errors.documents.delete.200"));
    }
    setIsLoading(false);
  };

  /**
   * @descr Create a temporary anchor element to allow downloading of files of a given format.
   * @param {Object} data data
   * @param {string} filename file name
   * @param {string} format format of the file to download
   */
  const downloadFile = (data: any) => {
    const { original_link, title } = data;
    let link = document.createElement("a");

    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      link.setAttribute("href", original_link);
      link.setAttribute("download", title);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    return true;
  };

  /**
   * @desc function used to delete a document
   * @param {object} event, click event object
   * @param {object} document_data, document data object
   **/
  const onClickDeleteDocument = (event: any, document_data?: any) => {
    event.preventDefault();
    if (document_data !== undefined) {
      dispatch(setSelectedDocuments([document_data]));
      onSetShowModalDocumentDeletion(true, [document_data]);
    }
    if (document_data === undefined) {
      onSetShowModalDocumentDeletion(true);
    }
  };

  /**
   * @desc function used to update a document
   * @param {object} event, click event object
   * @param {object} document_data, document data object
   **/
  const onClickEditDocument = (event: any, document_data: any) => {
    event.preventDefault();
    onSetShowModalDocumentDescription(true, document_data);
  };

  /**
   * @desc function used to add a document (show document modal ingestion)
   * @param {object} event, click event object
   **/
  const onClickAddDocument = (event: any) => {
    event.preventDefault();
    onSetShowModalDocumentIngestion(true);
  };

  /**
   * @desc function used to download a document
   * @param {object} event, click event object
   * @param {object} document_data, document data object
   **/
  const onClickDownloadDocument = (event: any, document_data: any) => {
    event?.preventDefault();

    const { original_link, title } = document_data;
    window.open(original_link, "_blank");
    // const link = document.createElement("a");
    // link.href = original_link;
    // link.download = title;
    // link.click();
  };

  /**
   * @descr Omit a specific field from an object passed as an argument
   * @param key field to omit
   * @param obj target object
   * @returns object without the field identify
   */
  function omit(key: any, obj: any) {
    const { [key]: omitted, ...rest } = obj;
    return rest;
  }

  /**
   * @desc function used to update advanced filter
   * @param {object} event, input change event value
   * @param {string} field, filter string value
   **/
  const onChangeSearchText = (event: any, field: string) => {
    setSearchText(event.target.value);
  };

  /**
   * @desc function used to set the visibility of filter sidebar
   * @param {object} event, click event value
   **/
  const onClickFilter = (event: any) => {
    event.preventDefault();
    onSetShowFilterSidebar(true);
  };

  /**
   * useEffect callback used to set search text, named title, in filters
   *
   **/

  useEffect(() => {
    let advanced_filters =
      searchText === ""
        ? omit("title", filters)
        : { ...filters, title: searchText };
    onSetFilters(advanced_filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const clearValue = () => {
    let advanced_fitlers: any = omit("title", filters);
    onSetFilters(advanced_fitlers);
  };

  return (
    <Container className="h-100">
      {(isPendingRequest || isLoading) && <Loader absolute={true} />}
      {/* Header info */}
      <Row
        style={{
          margin: "0 0 1rem -1rem",
        }}
      >
        <Col className="text-start">
          <h3 style={{ fontSize: "1.3rem" }}>
            <FaListUl
              style={{
                marginRight: ".5rem",
                marginBottom: ".2rem",
                fontSize: 18,
              }}
            />
            {t("views.documents.list.title")}
          </h3>
        </Col>
      </Row>

      {/* Documents filter */}
      <Row>
        <Grid container>
          <Grid item xs={6}>
            <InputText
              label=""
              placeholder={t("views.documents.list.inputPlaceholder")}
              value={filters.title !== undefined ? filters.title : ""}
              headComponent={
                <IoSearch style={{ color: "#15ccff", marginLeft: ".25rem" }} />
              }
              onChangeHandler={(event: any) =>
                onChangeSearchText(event, "title")
              }
              action={clearValue}
            />
          </Grid>
          <Grid item xs={2} sx={{ marginLeft: "auto" }}>
            <Button
              variant="light"
              onClickHandler={onClickFilter}
              style={{ marginBottom: "1rem" }}
            >
              <small style={{ fontSize: 14 }}>
                {t("views.documents.list.filterButtonText")}
              </small>
              {Object.keys(filters).length === 0 && (
                <FiFilter
                  style={{ marginLeft: ".3rem", marginBottom: ".1rem" }}
                />
              )}
              {Object.keys(filters).length > 0 && (
                <FaFilter
                  style={{ marginLeft: ".3rem", marginBottom: ".1rem" }}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </Row>

      {/* Table documents data */}
      <Row>
        <Col>
          <DocumentsTable
            isPendingRequest={isPendingRequest}
            namespacesData={namespacesData}
            batchDelete={batchDelete}
            onClickDeleteDocument={onClickDeleteDocument}
            onClickDownloadDocument={onClickDownloadDocument}
            onClickEditDocument={onClickEditDocument}
            resetTableParams={resetTableParams}
          />
        </Col>
      </Row>

      <StickyFooter>
        <Grid item xs={2}>
          <Button
            onClickHandler={onClickAddDocument}
            style={{
              height: 45,
              width: 45,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BsFileEarmarkPlus
              style={{
                color: "white",
                fontSize: 18,
              }}
            />
          </Button>
          <div
            style={{
              height: 45,
              display: "grid",
              placeItems: "center",
              marginLeft: ".5rem",
            }}
          >
            <small style={{ fontWeight: "bold" }}>
              {" "}
              {t("views.documents.list.uploadButtonText")}{" "}
            </small>
          </div>
        </Grid>
        <Grid item xs={2}>
          <Button
            onClickHandler={() => navigate("/documents/status")}
            style={{
              height: 45,
              width: 45,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GrStatusGood style={{ color: "white", fontSize: 18 }} />
          </Button>
          <div
            style={{
              height: 45,
              display: "grid",
              placeItems: "center",
              marginLeft: ".5rem",
            }}
          >
            <small style={{ fontWeight: "bold" }}>
              {" "}
              {t("views.documents.list.redirectButton")}{" "}
            </small>
          </div>
        </Grid>
      </StickyFooter>

      <CustomModal
        singleButton={false}
        textList={[
          "views.documents.list.modals.delete.bulkDocumentText",
          (selectedDocuments as TableData[])
            .map((item) => ` ${item.title} (in ${item.namespace_name})`)
            .toString(),
        ]}
        showModal={batchDeleteModal}
        onConfirm={confirmedBatchDelete}
        onCloseModal={() => {
          setBatchDeleteModal(false);
          dispatch(setSelectedDocuments([]));
        }}
      />

      <CustomModal
        singleButton={true}
        button1Text="OK"
        textList={["modal.documents.error", errors.toString()]}
        showModal={batchDeleteError}
        onConfirm={() => {
          setBatchDeleteError(false);
          setErrors([]);
          setResetTableParams((prev) => prev + 1);
        }}
      />
    </Container>
  );
};

export default TableDocumentsComponent;
