import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Loader } from "../../../../core/components/layout";
import { InputText } from "../../../../core/components/form";
import {
  NamespacesData,
  SelectedNamespaces,
  OnSetShowFilterSidebar,
  Filters,
  OnSetFilters,
  OnSetSelectedNamespaces,
  OnSetShowModalNamespaceDeletion,
  OnSetShowModalNamespaceCreation,
  OnSetShowModalNamespaceMetadata,
  OnSetActiveNamespace,
  IsPendingRequest,
} from "../../types";
import { IoSearch } from "react-icons/io5";
import { FaFilter, FaListUl } from "react-icons/fa6";
import { FiFilter } from "react-icons/fi";
import { LuAtom } from "react-icons/lu";
// hooks
import useScreenSize from "../../../../hooks/useScreenSize";

//services
import { sortJsonByKey, dateToTimestamp } from "../../../../core/services";
import { useTranslation } from "react-i18next";
//styles
import NamespaceTable from "./NamespaceTable";
import { Data } from "./NamespaceType";
import { deleteNamespace } from "../../sources/services";
import CustomModal from "../../../../commons/CustomModal";
import { Grid } from "@mui/material";
import StickyFooter from "../../../../commons/StickyFooter";

interface TableNamespaceProps {
  isPendingRequest: IsPendingRequest;
  filters: Filters;
  namespacesData: NamespacesData;
  selectedNamespaces: SelectedNamespaces;
  onSetFilters: OnSetFilters;
  onSetSelectedNamespaces: OnSetSelectedNamespaces;
  onSetShowFilterSidebar: OnSetShowFilterSidebar;
  onSetActiveNamespace: OnSetActiveNamespace;
  onSetShowModalNamespaceCreation: OnSetShowModalNamespaceCreation;
  onSetShowModalNamespaceMetadata: OnSetShowModalNamespaceMetadata;
  onSetShowModalNamespaceDeletion: OnSetShowModalNamespaceDeletion;
}

const TableNamespaceComponent: React.FC<TableNamespaceProps> = ({
  filters,
  namespacesData,
  selectedNamespaces,
  isPendingRequest,
  onSetFilters,
  onSetShowFilterSidebar,
  onSetActiveNamespace,
  onSetShowModalNamespaceCreation,
  onSetShowModalNamespaceMetadata,
  onSetShowModalNamespaceDeletion,
}) => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [batchDeleteModal, setBatchDeleteModal] = useState<boolean>(false);
  const [itemsToBeDeleted, setItemsToBeDeleted] = useState<Data[]>([]);
  const [batchDeleteError, setBatchDeleteError] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const screenSize = useScreenSize();

  /**
   * @desc function used to set the selected namespace from table (used for deletion)
   * @param {number} id, namespace id
   **/
  const setSelectedNamespaces = (id: string) => {
    if (selectedNamespaces.some((item: any) => item._id === id)) {
      let selected_documents = selectedNamespaces.filter(
        (item: any) => item._id !== id
      );
      return selected_documents;
    } else {
      let selected_namespaces = namespacesData.filter(
        (item: any) => item._id === id
      );
      return selectedNamespaces.concat(selected_namespaces);
    }
  };

  const batchDelete = useCallback((selected: Data[]) => {
    setBatchDeleteModal(true);
    setItemsToBeDeleted(selected);
  }, []);

  const confirmedBatchDelete = () => {
    setBatchDeleteModal(false);
    let notDeleted: Data[] = [];
    itemsToBeDeleted.every(async (item) => {
      setIsLoading(true);
      let resp = await deleteNamespace({ _id: item._id });
      if (resp.code !== undefined) {
        notDeleted.push(item);
      }
      setBatchDeleteModal(false);
      setItemsToBeDeleted([]);

      if (notDeleted.length > 0) {
        setErrors(notDeleted.map((item) => item.name));
        setBatchDeleteError(true);
      } else {
        window.location.reload();
      }
    });
  };

  /**
   * @descr function used as callback to delete single or multiple selected namespace data
   * @param {Object} event click event trigger
   * @param {Array<any>} namespace_data namespaces data
   */
  const deleteNamespece = (event: any, namespace_data: Data) => {
    event.preventDefault();
    if (namespace_data !== undefined) {
      const { _id } = namespace_data;
      let selected_documents = setSelectedNamespaces(_id);
      onSetShowModalNamespaceDeletion(true, selected_documents);
    }
    if (namespace_data === undefined) {
      onSetShowModalNamespaceDeletion(true);
    }
    onSetShowModalNamespaceDeletion(true);
  };

  /**
   * @descr function used as callback to set the selected namespaces data from the table on the global state
   * @param {Object} event click event trigger
   * @param {Object} namespace_data selected namespace data
   */
  const onClickEditNamespaceRoles = (event: any, namespace_data: any) => {
    event.preventDefault();
    onSetActiveNamespace(namespace_data);
  };

  /**
   * @descr function used as callback to open the update metadata modal.
   * @param {Object} event click event trigger
   * @param {Object} namespace_data selected row namespace data
   */
  const onClickEditNamespace = (event: any, namespace_data: any) => {
    event.preventDefault();
    onSetShowModalNamespaceMetadata(true, namespace_data);
  };

  /**
   * @descr function used as callback to show the creation namespace modal
   * @param {Object} event click event trigger
   */
  const onClickAddNamespace = (event: any) => {
    event.preventDefault();
    onSetShowModalNamespaceCreation(true);
  };

  /**
   * @descr Omit a specific field from an object passed as an argument
   * @param key field to omit
   * @param obj target object
   * @returns object without the field identify
   */
  function omit(key: any, obj: any) {
    const { [key]: omitted, ...rest } = obj;
    return rest;
  }

  /**
   * @descr function used to set the advanced filters
   * @param {string} field identify the filter object field
   * @param {any} data filter data
   */
  const onChangeAdvancedFilter = (field: any, data: any) => {
    let advanced_fitlers =
      data === "" ? omit("name", filters) : { ...filters, [field]: data };
    onSetFilters(advanced_fitlers);
  };

  /**
   * @descr function used as callback to show the advanced filters sidebar
   * @param {Object} event click event trigger
   */
  const onClickFilter = (event: any) => {
    event.preventDefault();
    onSetShowFilterSidebar(true);
  };

  useEffect(() => {
    setIsLoading(true);
    if (namespacesData.length !== 0) {
      setTableData(namespacesData);
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [namespacesData]);

  useEffect(() => {
    const { name, language, date_start, date_end } = filters;
    let table_data: any = sortJsonByKey(
      namespacesData
        .map((item: any) => {
          return {
            ...item,
            icon:
              item.icon === null || item.icon === undefined ? null : item.icon,
            timestamp: dateToTimestamp(item.creation_date, {
              format: "YYYY_MM_DDTHH:mm:ssZ",
            }),
            selected: false,
          };
        })
        .map((item: any) => {
          if (
            selectedNamespaces.some((el: any) => item._id === el._id) === true
          )
            return {
              ...item,
              timestamp: dateToTimestamp(item.creation_date, {
                format: "YYYY_MM_DDTHH:mm:ssZ",
              }),
              selected: true,
            };
          if (
            selectedNamespaces.some((el: any) => item._id === el._id) === false
          )
            return {
              ...item,
              timestamp: dateToTimestamp(item.creation_date, {
                format: "YYYY_MM_DDTHH:mm:ssZ",
              }),
              selected: false,
            };
        })
        .filter((item: any) => {
          if (
            name === "" ||
            name === undefined ||
            item.name.toLowerCase().includes(name.toLowerCase())
          ) {
            return item;
          }
        })
        .filter((item: any) => {
          if (
            language === undefined ||
            language.length === 0 ||
            language[0].toLowerCase() === item.language.toLowerCase()
          ) {
            return item;
          }
        })
        .filter((item: any) => {
          if (
            date_start === undefined ||
            date_start === null ||
            item.timestamp >= date_start
          ) {
            return item;
          }
        })
        .filter((item: any) => {
          if (
            date_end === undefined ||
            date_end === null ||
            item.timestamp <= date_end
          ) {
            return item;
          }
        }),
      "timestamp"
    );

    setTableData(table_data);
  }, [namespacesData, filters]);

  const clearValue = () => {
    let advanced_fitlers: any = omit("name", filters);
    onSetFilters(advanced_fitlers);
  };

  return (
    <Container style={{ paddingLeft: "2rem", height: "100%" }}>
      {isPendingRequest && <Loader absolute={true} />}
      <Row style={{ margin: "0 0 1rem -1rem" }}>
        <div style={{ textAlign: "left" }}>
          <h3 style={{ fontSize: "1.3rem" }}>
            <FaListUl style={{ marginRight: ".5rem", marginBottom: ".2rem" }} />
            {t("views.namespace.list.title")}
          </h3>
        </div>
      </Row>
      <Row>
        <Grid container>
          <Grid item xs={6}>
            <InputText
              label=""
              value={filters.name !== undefined ? filters.name : ""}
              placeholder={t("views.namespace.list.inputPlaceholder")}
              headComponent={
                <IoSearch style={{ color: "#15ccff", marginLeft: ".25rem" }} />
              }
              action={clearValue}
              onChangeHandler={(e: any) =>
                onChangeAdvancedFilter("name", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={2} sx={{ marginLeft: "auto" }}>
            <Button
              variant="light"
              onClickHandler={onClickFilter}
              style={{ marginBottom: "1rem" }}
            >
              <small style={{ fontSize: 14 }}>
                {t("views.namespace.list.filterButtonText")}
              </small>
              {Object.keys(filters).length === 0 && (
                <FiFilter
                  style={{ marginLeft: ".3rem", marginBottom: ".1rem" }}
                />
              )}
              {Object.keys(filters).length > 0 && (
                <FaFilter
                  style={{ marginLeft: ".3rem", marginBottom: ".1rem" }}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </Row>
      <Row>
        <Col>
          {isLoading ? (
            <Loader absolute={true} />
          ) : (
            <NamespaceTable
              data={tableData}
              batchDelete={batchDelete}
              onClickDeleteNamespace={deleteNamespece}
              onClickEditNamespaceRoles={onClickEditNamespaceRoles}
              onClickEditNamespace={onClickEditNamespace}
            />
          )}
        </Col>
      </Row>
      <StickyFooter>
        <div
          style={{
            display: "flex",
            paddingBottom: screenSize.height > 650 ? 45 : 0,
          }}
        >
          <Button
            onClickHandler={onClickAddNamespace}
            style={{
              height: 45,
              width: 45,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LuAtom style={{ color: "white", fontSize: 18 }} />
          </Button>
          <div
            style={{
              height: 45,
              display: "grid",
              placeItems: "center",
              marginLeft: ".5rem",
            }}
          >
            <small style={{ fontWeight: "bold" }}>
              {" "}
              {t("views.namespace.list.createButtonText")}{" "}
            </small>
          </div>
        </div>
      </StickyFooter>

      <CustomModal
        singleButton={false}
        textList={[
          "views.namespace.list.modals.delete.bulk",
          itemsToBeDeleted.map((item) => ` ${item.name}`).toString(),
        ]}
        showModal={batchDeleteModal}
        onConfirm={confirmedBatchDelete}
        onCloseModal={() => {
          setBatchDeleteModal(false);
          setItemsToBeDeleted([]);
        }}
      />
      <CustomModal
        singleButton={true}
        button1Text="OK"
        textList={["modal.namespaces.error", errors.toString()]}
        showModal={batchDeleteError}
        onConfirm={() => {
          setBatchDeleteError(false);
          setErrors([]);
          window.location.reload();
        }}
      />
    </Container>
  );
};

export default TableNamespaceComponent;
