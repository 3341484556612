import { useCallback, useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarSubHeader,
} from "../../commons";
import { SidebarLayout } from "../../layout";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import SelectNamespace from "./SelectNamespace";
import ChatContent from "./ChatContent";
import { Namespace } from "../../data-structures/NamespaceType";
import useChatApi from "../../api/ChatApi";
import {
  BotMessage,
  ChatData,
  Feedback,
  Message,
} from "../../data-structures/ChatType";
import CustomModal from "../../commons/CustomModal";
import { HcError } from "../../api/ApiErrors";
import NewChat from "./NewChat";

type Props = {
  namespaces: Namespace[];
};

const emptyChatHistory = { chat_id: "", namespace_id: "", messages: [] };

export default function ChatView({ namespaces }: Props) {
  const [searchParams] = useSearchParams();
  const [selectedNamespace, setSelectedNamespace] = useState<Namespace>();
  const {
    addNewChat,
    getChatHistory,
    sendUserMessage,
    stopStreaming,
    generateChatTitle,
    deleteChat,
  } = useChatApi();
  const [chatHistory, setChatHistory] = useState<ChatData>(emptyChatHistory);
  const [chatMessages, setChatMessages] = useState<(Message | BotMessage)[]>(
    []
  );
  const [streamingStatus, setStreamingStatus] = useState<
    "stop" | "streaming" | "pending"
  >("stop");
  const [openChatNotFound, setOpenChatNotFound] = useState<boolean>(false);
  const [chatError, setChatError] = useState<{
    errorCode: number;
    chatId: string;
  } | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/").pop();
  const streamingRef = useRef("stopped");

  useEffect(() => {
    if (path !== "new" && path !== "chat" && path !== "") {
      (async () => {
        let chatHistory = await getChatHistory(path!);
        if (chatHistory instanceof HcError) {
          setOpenChatNotFound(true);
          return;
        }
        setChatHistory(chatHistory);
      })();
    }
  }, [searchParams, getChatHistory, path, navigate]);

  useEffect(() => {
    let ns = namespaces.reduce((selected, ns) => {
      return ns._id === chatHistory.namespace_id ? ns : selected;
    }, {} as Namespace);
    if (ns._id !== undefined) setSelectedNamespace(ns);
  }, [namespaces, chatHistory]);

  useEffect(() => {
    setChatMessages(chatHistory.messages);
  }, [chatHistory]);

  const handleClickNamespace = (namespace: Namespace) => {
    setSelectedNamespace(namespace);
    navigate("/chat/new");
  };

  const handleFirstMessage = async (message: string) => {
    addNewChat(selectedNamespace!._id).then((resp) => {
      setChatHistory({
        chat_id: resp.chat_id,
        namespace_id: selectedNamespace!._id,
        messages: [],
      });
      handleSendMessage(message, resp.chat_id);
      navigate(`/chat/${resp.chat_id}`);
    });
  };

  const handleSendMessage = async (message: string, chatId: string) => {
    streamingRef.current = "started";
    if (chatMessages.length === 0) {
      await generateChatTitle(chatId, message);
    }
    setStreamingStatus("pending");
    //Temporary questioon and response id
    let questionId: string = uuidv4();
    let messageId: string = uuidv4();
    //Temporary question data
    let question = {
      message_date: "",
      message_id: questionId,
      message_type: "question",
      message_author: "user",
      message_text: message,
    };

    let aiResp: BotMessage = {
      message_date: "",
      message_id: messageId,
      message_type: "message",
      connected_message_id: questionId,
      chunks: [],
      feedback: {} as Feedback,
      message_author: "CHATBOT",
      message_text: "",
    };

    let messages = [...chatMessages, question];
    setChatMessages(messages);
    let updatedMessages = [...messages, aiResp];
    setChatMessages(updatedMessages);
    let chatResponse = await sendUserMessage(chatId, message);
    if ([500, 403, 404, 412, 422].includes(chatResponse)) {
      if ([500, 412].includes(chatResponse)) {
        setChatError({ errorCode: chatResponse, chatId });
      }

      setStreamingStatus("stop");
      setChatMessages([...chatMessages]);
      return;
    }
    //Text decoder
    const decoder = new TextDecoder("utf-8");
    let reader = chatResponse.getReader();

    reader
      .read()
      .then(async function readStream({
        done,
        value,
      }: {
        done: boolean;
        value: Uint8Array;
      }) {
        if (done) {
          setStreamingStatus("stop");
          return;
        }
        let decoded_chat = decoder.decode(value);
        let lst_rsp = decoded_chat.split("\n\n");
        lst_rsp = lst_rsp.filter((x) => !!x);
        let clean_rsp: any = lst_rsp.pop();
        clean_rsp = clean_rsp.replace("data: ", "");
        let json_rsp = JSON.parse(clean_rsp);

        let aiResp: BotMessage = {
          message_date: "",
          message_id: json_rsp.response_id,
          message_type: "message",
          connected_message_id: questionId,
          chunks: [],
          feedback: {} as Feedback,
          message_author: "CHATBOT",
          message_text: json_rsp.content,
        };

        if (json_rsp.status === "done" || streamingRef?.current === "stopped") {
          let lastMessageData = await getChatHistory(
            chatId,
            json_rsp.response_id
          );

          aiResp.chunks = (lastMessageData.messages[0] as BotMessage).chunks;
          aiResp.feedback = (
            lastMessageData.messages[0] as BotMessage
          ).feedback;
        }
        let newMessages: (Message | BotMessage)[] = updatedMessages.map(
          (item) => {
            if (item.message_id === messageId) {
              return aiResp;
            } else {
              return item;
            }
          }
        );
        if (streamingRef?.current === "started") {
          setStreamingStatus("streaming");
        }
        setChatMessages(newMessages);
        return reader.read().then(readStream);
      })
      .catch((err: any) => console.error(err));
  };

  const handleStopStreaming = (chatId: string, messageId: string) => {
    stopStreaming(chatId, messageId).finally(async () => {
      streamingRef.current = "stopped";
      setStreamingStatus("stop");
    });
  };

  const onResetHistory = useCallback(() => {
    setChatHistory(emptyChatHistory);
    setChatMessages([]);
  }, []);

  const handleCloseNoChunkModal = () => {
    if (!chatMessages.length && chatError) {
      deleteChat(chatError.chatId, false);
      navigate("/chat");
    }
    setChatError(null);
  };

  return (
    <>
      <SidebarLayout
        header={<SidebarHeader />}
        subHeader={<SidebarSubHeader onClickAddNewChatHandler={() => {}} />}
        body={
          <SidebarBody
            chats={[]}
            onClickAddNewChatHandler={() => {}}
            onClickDeleteAllChat={() => {}}
          />
        }
        footer={<SidebarFooter />}
      >
        <Routes>
          <Route
            path=""
            element={
              <SelectNamespace
                namespaces={namespaces}
                handleClickNamespace={handleClickNamespace}
                handleResetHistory={onResetHistory}
              />
            }
          />
          <Route
            path="new"
            element={
              <NewChat
                namespace={selectedNamespace}
                onSendFirstMessage={handleFirstMessage}
                handleResetHistory={onResetHistory}
              />
            }
          />

          <Route
            path=":id"
            element={
              <ChatContent
                namespace={selectedNamespace}
                chatId={chatHistory.chat_id}
                messages={chatMessages}
                onSendMessage={handleSendMessage}
                streaming={streamingStatus}
                stopStreaming={handleStopStreaming}
              />
            }
          />
        </Routes>
      </SidebarLayout>

      <CustomModal
        singleButton={true}
        button1Text="OK"
        textList={["modal.chat.notFound", "modal.actions.contactAdmin"]}
        showModal={openChatNotFound}
        onConfirm={() => {
          setOpenChatNotFound(false);
          navigate("/chat");
        }}
      />

      <CustomModal
        singleButton={true}
        button1Text="OK"
        textList={
          chatError?.errorCode === 412
            ? ["modal.chat.412", "modal.actions.contactSupervisor"]
            : ["modal.chat.500"]
        }
        showModal={!!chatError}
        onConfirm={handleCloseNoChunkModal}
      />
    </>
  );
}
