import { useCallback } from "react";
import { getCookie } from "../core/services";
import { REST_API_URL } from "../custom.config";
import { ID } from "../config";
import { TableData, DocumentWithStatus } from "../data-structures/DocumentType";
import { Toast } from "../core/components/layout";
import { t } from "i18next";
import type { PaginationMetadata } from "../commons/types";
import type {
  FilterParams,
  FilterParamsWithStatus,
  PaginationParams,
  OrderParams,
  OrderParamsWithStatus,
} from "../data-structures/DocumentType";

type DocumentsApi = {
  getDocumentsStatus: (
    paginationParams: PaginationParams,
    filters: FilterParamsWithStatus,
    orderParams: OrderParamsWithStatus
  ) => Promise<{
    documents: DocumentWithStatus[];
    pagination_metadata: PaginationMetadata;
  }>;
  getDocuments: (
    paginationParams: PaginationParams,
    filters: FilterParams,
    orderParams: OrderParams
  ) => Promise<{
    documents: TableData[];
    pagination_metadata: PaginationMetadata;
  }>;
  getUserOnDocumentList: (
    namespaces_ids: string[] | undefined
  ) => Promise<{ users: string[] }>;
  getDocumentUsersByNamespaceList: (
    namespaces_ids: string[] | undefined
  ) => Promise<{ users: string[] }>;
};

export default function useDocumentsApi(): DocumentsApi {
  const getDocumentsStatus = useCallback(
    async (
      paginationParams: PaginationParams,
      filterParams: FilterParamsWithStatus,
      orderParams: OrderParamsWithStatus
    ) => {
      const { orderBy, order } = orderParams;
      let baseUrl = new URL(
        `${REST_API_URL}/api/hyperchat/handle_documents/retrieve_staging`
      );
      let params = new URLSearchParams();
      Object.entries(paginationParams).map(([key, value]) => {
        params.append(key, value.toString());
        return true;
      });
      Object.entries(filterParams).map(([key, value]) => {
        if (
          value !== undefined &&
          value !== null &&
          typeof value !== "object"
        ) {
          params.append(key, value);
        }
        if (
          value !== undefined &&
          value !== null &&
          typeof value === "object" &&
          key !== "namespaces_names"
        ) {
          value.every((element) => {
            params.append(key, element);
            return true;
          });
        }
      });
      if (orderBy === "start_upload_date") {
        params.append("sort_by", `${order}upload_date`);
      } else params.append("sort_by", `${order}${orderBy}`);
      const response = await fetch(`${baseUrl}?${params}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
        method: "GET",
      });

      // Check response
      if (response.ok) {
        const pagination = response.headers.get("X-Pagination");
        if (pagination === null) {
          Toast("error", t(`errors.documents.retrieve.${response.status}`));
          return { documents: [], pagination_metadata: {} };
        }

        const data = await response.json();
        return {
          documents: data.documents,
          pagination_metadata: JSON.parse(pagination),
        };
      } else {
        let err = await response.json();
        Toast("error", t(`errors.documents.retrieve.${err.code}`));
        return { documents: [], pagination_metadata: {} };
      }
    },
    []
  );

  const getDocuments = useCallback(
    async (
      paginationParams: PaginationParams,
      filterParams: FilterParams,
      orderParams: OrderParams
    ) => {
      const { orderBy, order } = orderParams;
      let params = new URLSearchParams();
      Object.entries(paginationParams).map(([key, value]) => {
        params.append(key, value.toString());
        return true;
      });

      Object.entries(filterParams).map(([key, value]) => {
        if (typeof value === "string") {
          params.append(key, value);
        } else {
          value.forEach((element) => {
            params.append(key, element);
          });
        }
      });
      params.append("sort_by", `${order}${orderBy}`);

      let baseUrl = new URL(
        `${REST_API_URL}/api/hyperchat/handle_documents/retrieve_documents`
      );
      const response = await fetch(`${baseUrl}?${params}`, {
        headers: {
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
      });

      // Check response
      if (response.ok) {
        const pagination = response.headers.get("X-Pagination");
        if (pagination === null) {
          Toast("error", t(`errors.documents.retrieve.${response.status}`));
          return { documents: [], pagination_metadata: {} };
        }
        const data = await response.json();
        return {
          documents: data.documents,
          pagination_metadata: JSON.parse(pagination),
        };
      } else {
        let err = await response.json();
        Toast("error", t(`errors.documents.retrieve.${err.code}`));
        return { documents: [], pagination_metadata: {} };
      }
    },
    []
  );

  const getUserOnDocumentList = useCallback(
    async (namespaces_ids: string[] | undefined) => {
      let baseUrl = new URL(
        `${REST_API_URL}/api/hyperchat/handle_documents/retrieve_staging_users`
      );
      let params = new URLSearchParams();
      if (namespaces_ids !== undefined) {
        namespaces_ids.every((element) => {
          if (element !== "" && element !== null && element !== undefined)
            params.append("_id_namespaces", element);
          return true;
        });
      }

      const response = await fetch(`${baseUrl}?${params}`, {
        headers: {
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
      });

      // Check response
      if (response.ok) {
        return await response.json();
      } else {
        let err = await response.json();
        console.log(err);
        Toast("error", t(`errors.users.retrieve.${err.code}`));
        return [];
      }
    },
    []
  );

  const getDocumentUsersByNamespaceList = useCallback(
    async (namespaces_ids: string[] | undefined) => {
      let baseUrl = new URL(
        `${REST_API_URL}/api/hyperchat/handle_documents/retrieve_documents_users`
      );
      let params = new URLSearchParams();
      if (namespaces_ids !== undefined) {
        namespaces_ids.every((element) => {
          if (element !== "" && element !== null && element !== undefined)
            params.append("_id_namespaces", element);
          return true;
        });
      }

      const response = await fetch(`${baseUrl}?${params}`, {
        headers: {
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
      });

      // Check response
      if (response.ok) {
        return await response.json();
      } else {
        let err = await response.json();
        Toast("error", t(`errors.users.retrieve.${err.code}`));
        return [];
      }
    },
    []
  );

  return {
    getDocumentsStatus,
    getDocuments,
    getUserOnDocumentList,
    getDocumentUsersByNamespaceList,
  };
}
