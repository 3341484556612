import React, { useState, useEffect } from "react";
import { Row, Col, Offcanvas } from "react-bootstrap";
import { Button, Loader } from "../../../../core/components/layout";
import Calendar from "react-calendar";
import InputSearch from "../../../../core/components/form/InputSearch/InputSearch";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { timestampToDate } from "../../../../core/services/date.services";
import { BsCalendar4 } from "react-icons/bs";
import { CgCloseO } from "react-icons/cg";
import { FaRegUser } from "react-icons/fa";
import { LuAtom } from "react-icons/lu";
import useDocumentsApi from "../../../../api/DocumentsApi";
import {
  Filters,
  ShowFilterSidebar,
  OnSetFilters,
  OnSetShowFilterSidebar,
  DocumentsData,
  NamespacesData,
} from "../../types";
import { Namespace } from "../../../../data-structures/NamespaceType";
import { TableData } from "../../../../data-structures/DocumentType";

// const DEFAULT_FILTER = {
//     date_start: null,
//     date_end: null,
//     namespaces: [],
//     users: []
// }
const DEFAULT_FILTER = {};

interface SidebarFiltersProps {
  filters: Filters;
  documentsData: DocumentsData;
  showFilterSidebar: ShowFilterSidebar;
  namespacesData: NamespacesData;
  onSetFilters: OnSetFilters;
  onSetShowFilterSidebar: OnSetShowFilterSidebar;
}

const SidebarFiltersComponent: React.FC<SidebarFiltersProps> = ({
  filters,
  documentsData,
  showFilterSidebar,
  namespacesData,
  onSetFilters,
  onSetShowFilterSidebar,
}) => {
  const [advancedFilter, setAdvancedFilter] = useState<any>(DEFAULT_FILTER);
  const [namespaces, setNamespaces] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const { t } = useTranslation();
  const { getDocumentUsersByNamespaceList } = useDocumentsApi();

  /**
   * @descr Omit a specific field from an object passed as an argument
   * @param key field to omit
   * @param obj target object
   * @returns object without the field identify
   */
  function omit(key: any, obj: any) {
    const { [key]: omitted, ...rest } = obj;
    return rest;
  }

  /**
   * @desc function used to close the sidebar wit filters
   * @param {object} event, click event value
   **/
  const onClickCloseFilter = (event: any) => {
    onSetShowFilterSidebar(false);
  };

  /**
   * @desc function used to update the filters using search inputs
   * @param {object} data, filter data value
   * @param {string} field, filter item string value
   **/
  const onChangeAdvancedFilter = (data: any, field: string) => {
    if (data.length === 0) {
      if (field === "namespaces") {
        console.log({ advancedFilter });
        let advanced_fitlers: any = omit("_id_namespaces", advancedFilter);
        let cleaned_filters: any = omit(field, advanced_fitlers);
        getDocumentUsersByNamespaceList([]).then((updatedUsers) =>
          setUsers(updatedUsers.users)
        );
        setAdvancedFilter(cleaned_filters);
      } else {
        let advanced_fitlers: any = omit(field, advancedFilter);
        setAdvancedFilter(advanced_fitlers);
      }
    }

    if (data.length > 0) {
      let advanced_fitlers: any;
      if (field === "namespaces") {
        let ns_ids = namespacesData
          .filter((ns: any) => data.includes(ns.name))
          .map((ns: Namespace) => ns._id);
        getDocumentUsersByNamespaceList(ns_ids).then((updatedUsers) =>
          setUsers(updatedUsers.users)
        );
        advanced_fitlers = {
          ...advancedFilter,
          [field]: data,
          ["_id_namespaces"]: ns_ids,
        };
      } else {
        advanced_fitlers = {
          ...advancedFilter,
          [field]: data,
        };
      }
      setAdvancedFilter(advanced_fitlers);
    }
  };

  /**
   * @desc function used to update the filters using calendar component
   * @param {object} data, filter data value
   * @param {string} field, filter item string value
   **/
  const onChangeAdvancedDateFilter = (data: any, field: string) => {
    let date_timestamp = data.getTime();
    let advanced_fitlers = { ...advancedFilter, [field]: date_timestamp };
    setAdvancedFilter(advanced_fitlers);
  };

  /**
   * @desc function used to save filter object
   **/
  const onClickConfirm = () => {
    onSetFilters(advancedFilter);
    onSetShowFilterSidebar(false);
  };

  /**
   * @desc function used to reset filter object
   **/
  const onClickReset = () => {
    if (filters.title !== undefined) onSetFilters({ title: filters.title });
    if (filters.title === undefined) onSetFilters({});
    setAdvancedFilter(DEFAULT_FILTER);
    onSetShowFilterSidebar(false);
  };

  /**
   * useEffect callback used to set filters
   **/
  useEffect(() => {
    if (Object.keys(filters).length > 0) setAdvancedFilter(filters);
    if (Object.keys(filters).length === 0) setAdvancedFilter(DEFAULT_FILTER);
  }, [filters]);

  /**
   * useEffect callback used to set users and namespaces options from documents data
   **/
  useEffect(() => {
    let namespaces_names = namespacesData.map((ns: any) => ns.name);

    const updateUserOptions = async () => {
      getDocumentUsersByNamespaceList(filters._id_namespaces).then((result) =>
        setUsers(result.users)
      );
    };
    let ignore = false;
    !ignore && updateUserOptions();
    setNamespaces(namespaces_names);
    return () => {
      ignore = true;
    };
  }, [showFilterSidebar, namespacesData]);

  return (
    <Offcanvas
      placement="end"
      show={showFilterSidebar}
      style={{ zIndex: 99999 }}
      onHide={onClickCloseFilter}
    >
      {namespaces.length === 0 && <Loader absolute={true} />}
      <Row
        style={{
          margin: "0 0.15rem",
          padding: "1rem 0 0.5rem 0",
          borderBottom: "1px solid #CCC",
        }}
      >
        <Col>
          <h6>
            <b>{t("views.documents.list.sidebar.title")}</b>
          </h6>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Button
            onClickHandler={onClickCloseFilter}
            variant="light"
            style={{ border: "none" }}
          >
            <CgCloseO style={{ fontSize: 20 }} />
          </Button>
        </Col>
      </Row>
      {/* Filters inputs */}
      <Scrollbars style={{ height: "calc(100vh - 115px)" }} autoHide={true}>
        <Row style={{ margin: "15px 0 5px 15px" }}>
          <Col>
            <small>
              <LuAtom style={{ marginRight: ".3rem" }} />
              <b>{t("views.documents.list.sidebar.namespaceInputLabel")}</b>
            </small>
          </Col>
        </Row>
        <Row style={{ margin: "0 15px" }}>
          <Col>
            <InputSearch
              label=""
              placeholder={t(
                "views.documents.list.sidebar.namespaceInputPlaceholder"
              )}
              options={namespaces}
              selected={advancedFilter.namespaces}
              multiple={true}
              onChangeHandler={(item: any) => {
                onChangeAdvancedFilter(item, "namespaces");
              }}
            />
          </Col>
        </Row>
        <Row style={{ margin: "0 0 10px 15px" }}>
          <Col>
            <small>
              <FaRegUser style={{ marginRight: ".3rem" }} />
              <b>{t("views.documents.list.sidebar.userInputLabel")}</b>
            </small>
          </Col>
        </Row>
        <Row style={{ margin: "0 15px" }}>
          <Col>
            <InputSearch
              label=""
              placeholder={t(
                "views.documents.list.sidebar.userInputPlaceholder"
              )}
              options={users}
              selected={advancedFilter.users}
              multiple={false}
              // multiple={true}
              onChangeHandler={(item: any) =>
                onChangeAdvancedFilter(item, "users")
              }
            />
          </Col>
        </Row>
        <Row style={{ margin: "0 0 0 15px" }}>
          <Col>
            <small style={{ fontSize: 14 }}>
              <BsCalendar4 style={{ marginRight: ".3rem", fontSize: 14 }} />
              <b>{t("views.documents.list.sidebar.dateFromLabel")} </b>
              {timestampToDate(advancedFilter.date_start, {
                format: "YYYY-MM-DD",
              }) !== null
                ? `${timestampToDate(advancedFilter.date_start, {
                    format: "YYYY-MM-DD",
                  })}`
                : ""}
            </small>
          </Col>
        </Row>
        <Row style={{ margin: "0 0 15px 0" }}>
          <Col>
            <div style={{ transform: "scale(0.8)" }}>
              <Calendar
                value={timestampToDate(advancedFilter.date_start, {
                  format: "YYYY-MM-DD",
                })}
                onChange={(e: any) =>
                  onChangeAdvancedDateFilter(e, "date_start")
                }
              />
            </div>
          </Col>
        </Row>
        <Row style={{ margin: "0 15px" }}>
          <Col>
            <small style={{ fontSize: 14 }}>
              <BsCalendar4 style={{ marginRight: ".3rem", fontSize: 14 }} />
              <b>{t("views.documents.list.sidebar.dateToLabel")} </b>
              {timestampToDate(advancedFilter.date_end, {
                format: "YYYY-MM-DD",
              }) !== null
                ? `${timestampToDate(advancedFilter.date_end, {
                    format: "YYYY-MM-DD",
                  })}`
                : ""}
            </small>
          </Col>
        </Row>
        <Row style={{ margin: "0 0 15px 0" }}>
          <Col>
            <div style={{ transform: "scale(0.8)" }}>
              <Calendar
                value={timestampToDate(advancedFilter.date_end, {
                  format: "YYYY-MM-DD",
                })}
                onChange={(e: any) => onChangeAdvancedDateFilter(e, "date_end")}
              />
            </div>
          </Col>
        </Row>
      </Scrollbars>

      {/** Footer buttons */}
      <Row style={{ marginTop: "15px" }}>
        <Col>
          <Button
            text={t("views.documents.list.sidebar.backButtonText")}
            variant="secondary"
            style={{
              color: "#000",
              width: "45%",
              marginLeft: "3%",
              marginRight: "2%",
            }}
            onClickHandler={onClickReset}
          />
          <Button
            text={t("views.documents.list.sidebar.confirmButtonText")}
            variant="primary"
            style={{ width: "45%", marginLeft: "2%" }}
            onClickHandler={onClickConfirm}
          />
        </Col>
      </Row>
    </Offcanvas>
  );
};

export default SidebarFiltersComponent;
