import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { Grid } from "@mui/material";
import EnhancedTableToolbar from "../../../../components/table/CustomTableToolbar";
import EnhancedTableHead from "../../../../components/table/CustomTableHead";
import { Button } from "../../../../core/components/layout";
import { FaRegTrashCan } from "react-icons/fa6";
import { RxPencil2 } from "react-icons/rx";
import { t } from "i18next";
import dayjs from "dayjs";
import { DocumentHeadCell, TableData } from "./DocumentsType";
import type {
  FilterParams,
  OrderParams,
} from "../../../../data-structures/DocumentType";
import { AiOutlineExport } from "react-icons/ai";
import { Order } from "../../../../components/table/TableOrder";

import { useAppDispatch, useAppSelector } from "../../../../store/app.hooks";
import type { RootState } from "../../../../store/app.store";
import useDocumentsApi from "../../../../api/DocumentsApi";
import type { Filters, PaginationMetadata } from "../../../../commons/types";
import { NamespacesData } from "../../../../data-structures/NamespaceType";
import {
  setDocumentsData,
  setFilters,
  setSelectedDocuments,
} from "../../sources/documents.reducer";
import TableLoader from "../../../../components/table/TableLoader";
import useScreenSize from "../../../../hooks/useScreenSize";

const headCells: readonly DocumentHeadCell[] = [
  {
    id: "namespace_name",
    numeric: false,
    disablePadding: true,
    label: "views.documents.list.table.namespace",
    width: "5%",
    sortable: true,
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "views.documents.list.table.documentName",
    width: "10%",
    sortable: true,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "views.documents.list.table.description",
    width: "10%",
    sortable: true,
  },
  {
    id: "user_id",
    numeric: false,
    disablePadding: false,
    label: "views.documents.list.table.uploadedBy",
    width: "10%",
    sortable: true,
  },
  {
    id: "upload_date",
    numeric: false,
    disablePadding: false,
    label: "views.documents.list.table.uploadDate",
    width: "10%",
    sortable: true,
  },
  {
    id: "_id_namespace",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "10%",
    sortable: true,
  },
];

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const defaultOrderParams: OrderParams = {
  orderBy: "upload_date",
  order: "-",
};
const defaultStartPage = 0;
const defaultRowNumber = 5;

type Props = {
  batchDelete: () => void;
  onClickDownloadDocument: (e: any, row: TableData) => void;
  onClickDeleteDocument: (e: any, row: TableData) => void;
  onClickEditDocument: (e: any, row: TableData) => void;
  namespacesData: NamespacesData;
  isPendingRequest: boolean;
  resetTableParams: number;
};

export default function DocumentsTable({
  batchDelete,
  onClickDeleteDocument,
  onClickDownloadDocument,
  onClickEditDocument,
  namespacesData,
  isPendingRequest,
  resetTableParams,
}: Props) {
  const dispatch = useAppDispatch();
  const [orderParams, setOrderParams] =
    React.useState<OrderParams>(defaultOrderParams);
  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(defaultStartPage);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] =
    React.useState<number>(defaultRowNumber);
  const [paginationMetaData, setPaginationMetaData] =
    React.useState<PaginationMetadata>({});
  const [allDocumentsOnPageSelected, setAllDocumentsOnPageSelected] =
    React.useState<boolean>(false);
  const filters: Filters = useAppSelector(
    (state: RootState) => state.document.filters
  );
  const selectedDocuments = useAppSelector(
    (state: RootState) => state.document.selectedDocuments
  );
  const documentsData = useAppSelector(
    (state: RootState) => state.document.documentsData
  );

  const { getDocuments } = useDocumentsApi();
  const screenSize = useScreenSize();
  const loaderHeightRef = React.useRef<number>(screenSize.height / 2 - 174);

  /**
   * useEffect callback used to reset all filters, paging and sorting to default => data is reloaded
   *
   **/

  React.useEffect(() => {
    setOrderParams(defaultOrderParams);
    dispatch(setFilters({}));
    setRowsPerPage(defaultRowNumber);
  }, [resetTableParams]);

  //load data when filters, paging or sorting changes
  React.useEffect(() => {
    const getDocs = async () => {
      setTableDataLoading(true);
      const paginationParams = {
        page: page + 1,
        page_size: rowsPerPage,
      };

      const filterParams: FilterParams = {};

      if (filters.users) {
        filterParams.user_id = filters.users[0];
      }
      if (filters.namespaces) {
        filterParams._id_namespaces = filters._id_namespaces;
      }
      if (filters.date_start) {
        filterParams.from_date = dayjs(filters.date_start)
          .tz("Europe/Rome")
          .startOf("day")
          .format();
      }
      if (filters.date_end) {
        filterParams.to_date = dayjs(filters.date_end)
          .tz("Europe/Rome")
          .endOf("day")
          .format();
      }
      if (filters.title) {
        filterParams.search_text = filters.title;
      }

      const result = await getDocuments(
        paginationParams,
        filterParams,
        orderParams
      );

      if (result.documents.length === 0) {
        // dispatch empty array
        if (!ignore) {
          setPaginationMetaData({});
          dispatch(setDocumentsData([]));
          setTableDataLoading(false);
        }
      }
      if (result.documents.length > 0 && namespacesData.length > 0) {
        // dispatch fetched documents with namespace name
        let init_table_data: any = [];
        result.documents.forEach((item: TableData) => {
          const name =
            namespacesData.find((ns) => ns._id === item._id_namespace)?.name ||
            "";

          init_table_data.push({
            ...item,
            namespace_name: name,
          });
        });

        if (!ignore) {
          setPaginationMetaData(result.pagination_metadata);
          dispatch(setDocumentsData(init_table_data));
          setTableDataLoading(false);
        }
      }
    };
    let ignore = false;
    getDocs();

    return () => {
      ignore = true;
    };
  }, [filters, page, rowsPerPage, orderParams, namespacesData]);

  React.useEffect(() => {
    const height = document.getElementById("documents-table")?.clientHeight;
    if (height && !tableDataLoading) {
      loaderHeightRef.current = height - 174;
    }
  }, [tableDataLoading, screenSize.height]);

  // check if all documents visualized on page are checked
  React.useEffect(() => {
    const allChecked =
      documentsData.findIndex((document) => !selected.includes(document._id)) <
      0;
    setAllDocumentsOnPageSelected(allChecked);
  }, [selected, documentsData]);

  React.useEffect(() => {
    setPage(0);
  }, [filters]);

  // get selectedIds
  React.useEffect(() => {
    const ids = selectedDocuments.map((item) => item._id);
    setSelected(ids);
  }, [selectedDocuments]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof TableData
  ) => {
    const isPrevAsc =
      orderParams.orderBy === property && orderParams.order === "+";
    setOrderParams({ orderBy: property, order: isPrevAsc ? "-" : "+" });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!allDocumentsOnPageSelected) {
      const docsToAdd: TableData[] = [];
      documentsData.forEach((document) => {
        if (!selected.includes(document._id)) {
          return docsToAdd.push(document);
        }
      });

      dispatch(setSelectedDocuments([...selectedDocuments, ...docsToAdd]));
      return;
    }
    dispatch(setSelectedDocuments([]));
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: TableData,
    id: number,
    index: number,
    isPreviouslySelected: boolean
  ) => {
    if (event.shiftKey) {
      let range = Array.from({ length: index + 1 }, (_, i) => 0 + 1 + i);
      // if not in selectedDocuments add it
      const itemsToAdd: TableData[] = [];
      range.map((index) => {
        itemsToAdd.push(documentsData[index - 1]);
      });

      itemsToAdd.forEach((item) => {
        if (
          (selectedDocuments as TableData[]).findIndex(
            (selected) => selected._id === item._id
          ) >= 0
        ) {
          itemsToAdd.filter((addItem) => addItem._id !== item._id);
        }
      });
      dispatch(setSelectedDocuments([...selectedDocuments, ...itemsToAdd]));
    } else {
      if (isPreviouslySelected) {
        (selectedDocuments as TableData[]).filter(
          (sel) => sel._id !== item._id
        );
        dispatch(
          setSelectedDocuments(
            (selectedDocuments as TableData[]).filter(
              (sel) => sel._id !== item._id
            )
          )
        );
        return;
      }
      dispatch(setSelectedDocuments([...selectedDocuments, item]));
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const emptyRows =
    paginationMetaData.last_page &&
    paginationMetaData.total_pages &&
    paginationMetaData.total_pages > 1
      ? Math.max(0, Math.min(rowsPerPage, 7) - documentsData.length)
      : 0;
  return (
    <div id="documents-table">
      <EnhancedTableToolbar
        numTotal={paginationMetaData.total || 0}
        numSelected={selected.length}
        handleBatchDelete={() => batchDelete()}
      />

      <Box
        sx={{
          width: "100%",
          border: "none",
          overflow: "auto",
          maxHeight: "50vh",
        }}
      >
        <TableContainer
          sx={{
            overflowX: "initial",
          }}
        >
          <Table
            stickyHeader
            sx={{
              minWidth: 750,
              tableLayout: "fixed",
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={orderParams.order === "+" ? "asc" : "desc"}
              orderBy={orderParams.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={documentsData.length}
              headCells={headCells}
              checkBoxWidth={"3%"}
              allChecked={allDocumentsOnPageSelected}
            />
            <TableBody>
              {!tableDataLoading &&
                (documentsData as TableData[]).map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) =>
                            handleClick(
                              event,
                              row,
                              row._id,
                              index,
                              isItemSelected
                            )
                          }
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          sx={{ color: "lightgrey" }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        title={row.namespace_name}
                        align="left"
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row.namespace_name}
                        </Typography>
                      </TableCell>
                      <TableCell title={row.title} align="left">
                        <Typography
                          variant="body2"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row.title}
                        </Typography>
                      </TableCell>
                      <TableCell title={row.description} align="left">
                        <Typography
                          variant="body2"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row.description}
                        </Typography>
                      </TableCell>
                      <TableCell title={row.user_id} align="left">
                        <Typography
                          variant="body2"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row.user_id}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        title={dayjs(row.upload_date).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {dayjs(row.upload_date).format("YYYY-MM-DD HH:mm:ss")}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Grid container>
                          <Grid item xs={4}>
                            <Button
                              variant="light"
                              disabled={
                                row.original_link === null ||
                                row.original_link === ""
                              }
                              onClickHandler={(e: any) =>
                                onClickDownloadDocument(e, row)
                              }
                            >
                              <AiOutlineExport
                                style={{
                                  color:
                                    row.original_link !== null &&
                                    row.original_link !== ""
                                      ? "rgb(0, 208, 255)"
                                      : "#C3C3C3",
                                }}
                              />
                            </Button>
                          </Grid>
                          <Grid item xs={4}>
                            <Button
                              variant="light"
                              onClickHandler={(e: any) =>
                                onClickEditDocument(e, row)
                              }
                            >
                              <RxPencil2
                                style={{ color: "rgb(0, 208, 255)" }}
                              />
                            </Button>
                          </Grid>
                          <Grid item xs={4}>
                            <Button
                              variant="light"
                              onClickHandler={(e: any) =>
                                onClickDeleteDocument(e, row)
                              }
                            >
                              <FaRegTrashCan
                                style={{ color: "rgb(0, 208, 255)" }}
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && !tableDataLoading && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 64) * emptyRows,
                  }}
                ></TableRow>
              )}
            </TableBody>
          </Table>
          <TableLoader
            height={loaderHeightRef.current}
            show={
              tableDataLoading || (isPendingRequest && !documentsData.length)
            }
            animation={!isPendingRequest}
          />
        </TableContainer>
      </Box>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={paginationMetaData.total ? paginationMetaData.total : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("views.namespace.list.table.options.pagination")}
        sx={{ "& .MuiTablePagination-toolbar": { alignItems: "baseline" } }}
      />
    </div>
  );
}
