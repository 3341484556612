import { Grid } from "@mui/material";
import useScreenSize from "../hooks/useScreenSize";

type Props = {
  children: React.ReactNode;
  width?: number | string;
  maxWidth?: number | string;
};

const StickyFooter: React.FC<Props> = ({
  children,
  maxWidth = 1200,
  width = "100%",
}) => {
  const screenSize = useScreenSize();
  const getBottomtPadding = () => {
    if (screenSize.height < 650) return 0;
    if (screenSize.height < 700) return screenSize.height * 0.01;
    if (screenSize.height < 800) return screenSize.height * 0.03;
    if (screenSize.height < 900) return screenSize.height * 0.05;
    return screenSize.height * 0.1;
  };
  return (
    <Grid
      container
      style={{
        position: "absolute",
        bottom: 0,
        width,
        maxWidth,
        paddingBottom: getBottomtPadding(),
      }}
    >
      {children}
    </Grid>
  );
};

export default StickyFooter;
