import React from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Modal, Loader } from "../../../../core/components/layout";
import { useTranslation } from "react-i18next";
import { FaRegTrashCan } from "react-icons/fa6";
import {
  IsPendingRequest,
  ShowModalDocumentDeletion,
  SelectedDocuments,
  OnSetShowModalDocumentDeletion,
  OnDeleteDocuments,
} from "../../types";

interface ModalDocumentsDeleteProps {
  isPendingRequest: IsPendingRequest;
  showModalDocumentDeletion: ShowModalDocumentDeletion;
  selectedDocuments: SelectedDocuments;
  onDeleteDocuments: OnDeleteDocuments;
  onSetShowModalDocumentDeletion: OnSetShowModalDocumentDeletion;
}

const ModalDocumentsDeleteComponents: React.FC<ModalDocumentsDeleteProps> = ({
  isPendingRequest,
  showModalDocumentDeletion,
  selectedDocuments,
  onDeleteDocuments,
  onSetShowModalDocumentDeletion,
}) => {
  const { t } = useTranslation();

  /**
   * @desc function used as callback of close modal event, useful to reset the component state
   **/
  const onCloseModal = () => {
    if (!isPendingRequest) onSetShowModalDocumentDeletion(false);
  };

  /**
   * @desc function used to delete a document
   **/
  const onClickDelete = () => {
    onDeleteDocuments(selectedDocuments);
  };

  return (
    <Modal
      visible={showModalDocumentDeletion}
      size="lg"
      onHideHandler={onCloseModal}
    >
      {isPendingRequest && <Loader absolute={true} />}
      <Row style={{ marginTop: "2rem" }}>
        <Col className="text-center">
          <FaRegTrashCan style={{ fontSize: 24, color: "#C2C2C2" }} />
        </Col>
      </Row>
      <Row style={{ marginTop: "2rem" }}>
        <Col className="text-center" style={{ padding: "0 5rem" }}>
          <h5>
            {selectedDocuments.length === 1 && (
              <div>
                <span>
                  {t("views.documents.list.modals.delete.singleDocumentText")}
                </span>
                <br />
                <br />
                <small style={{ margin: "1rem 0" }}>
                  {selectedDocuments[0].title +
                    " | " +
                    selectedDocuments[0].namespace_name}
                </small>
              </div>
            )}
            {selectedDocuments.length !== 1 && (
              <div>
                <span>
                  {t("views.documents.list.modals.delete.bulkDocumentText")}
                </span>
                <br />
                <br />
                <small style={{ margin: "1rem 0" }}>
                  {selectedDocuments.map((item: any, index: any) => {
                    if (index < selectedDocuments.length - 1) {
                      return (
                        <span style={{ margin: "1rem 0" }} key={item._id}>
                          {item.title + " | " + item.namespace_name + ", "}
                          <br />
                        </span>
                      );
                    }
                    return (
                      <span style={{ margin: "1rem 0" }} key={item._id}>
                        {" "}
                        {item.title + " | " + item.namespace_name + "."}
                      </span>
                    );
                  })}
                </small>
              </div>
            )}
          </h5>
        </Col>
      </Row>
      <Row>
        <Col
          className="text-center"
          style={{ marginTop: "2rem", marginBottom: "1.5rem" }}
        >
          <Button
            text={t("views.documents.list.modals.delete.backButtonText")}
            variant="secondary"
            outline={true}
            onClickHandler={onCloseModal}
            disabled={isPendingRequest}
            style={{ width: "8rem", marginRight: "1rem" }}
          />
          <Button
            text={t("views.documents.list.modals.delete.confirmButtonText")}
            onClickHandler={onClickDelete}
            style={{ width: "8rem" }}
            disabled={isPendingRequest}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalDocumentsDeleteComponents;
