import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SidebarLayout } from "../layout";
import {
  SidebarHeader,
  SidebarBody,
  SidebarFooter,
  SidebarSubHeader,
  UnregistedUserModal,
} from "../commons";
import {
  createCookie,
  deleteCookie,
  deleteFromStorage,
  getFromStorage,
  saveToStorage,
} from "../core/services";
import { ID } from "../config";
import {
  REACT_APP_CLIENT_ID,
  REACT_APP_CODE_VERIFIER,
  REACT_APP_REDIRECT_URL,
} from "../custom.config";
import {
  getNamespacesData,
  getUserInfo,
  getUsersData,
} from "../views/users/sources/services";
import { store } from "../store/app.store";
import {
  setIsPendingRequest,
  setUserInfo,
  setUsersData,
} from "../views/users/sources/users.reducer";
import { useSelector } from "react-redux";
import {
  requestGetUsersData,
  requestSetUserSession,
  requestDeleteAllChat,
} from "../views/users/sources/users.effects";

const REDIRECT_URL: string = REACT_APP_REDIRECT_URL
  ? REACT_APP_REDIRECT_URL
  : `${window.location.origin}/login`;
const SESSION_CHECK_TIME: number = 300000;

type Props = {
  children: React.ReactNode;
};

const WrapWithSidebar: React.FC<Props> = ({ children }) => {
  const userInfo = useSelector((state: any) => state.users.userInfo);
  const showModalUnregisteredUser = useSelector(
    (state: any) => state.users.showModalUnregisteredUser
  );
  const userToken = useSelector((state: any) => state.users.userToken);
  const chatsData = useSelector((state: any) => state.users.chatsData);

  const navigate = useNavigate();

  /**
   * @descr function used to empty the storage from the current user session and return to the login page
   */
  const onDestroyUserSession = () => {
    let storage: any = deleteFromStorage(`user_${ID}`),
      cookie: any = deleteCookie(`token_${ID}`);

    if (storage && cookie) window.location.reload();
  };

  /**
   * @desc function used to refresh the user session sending refresh_token
   **/
  const onSetNewUserSession = () => {
    let user_tokens = getFromStorage(`user_${ID}`);
    if (user_tokens) {
      const { refresh_token } = user_tokens;
      let user_form_data = new FormData();
      user_form_data.append("client_id", REACT_APP_CLIENT_ID);
      user_form_data.append("grant_type", "refresh_token");
      user_form_data.append("refresh_token", refresh_token);
      user_form_data.append("redirect_uri", REDIRECT_URL);
      user_form_data.append("code_verifier", REACT_APP_CODE_VERIFIER);
      //Refresh token effect
      requestSetUserSession(user_form_data);
    }
  };

  /**
   * @desc function used to check the user session status
   **/
  const checkUserSession = () => {
    console.log("Checking user session...");
    let session_data: any = getFromStorage(`user_${ID}`);
    let check_timestamp = new Date().getTime();
    const { expire_date } = session_data;
    //Current time is greater than the cookie expiry time or less than 5 minutes
    if (
      check_timestamp >= expire_date ||
      expire_date - check_timestamp < SESSION_CHECK_TIME
    ) {
      onSetNewUserSession();
    }
  };

  /**
   * @descr function used as callback to retrieve the user chats data
   * @param {Object} event click event trigger
   */
  const onClickAddNewChat = (event: any) => {
    event.preventDefault();
    navigate(`/chat`);
  };

  /**
   * OnClick add new chat button callback used to delete all user chat
   * @param {object} event, click event data
   * @param {Obejct} user_data user email data
   */
  const onClickDeleteAllChat = (event: any, user_data: any) => {
    event?.preventDefault();
    requestDeleteAllChat(user_data);
  };

  /**
   * function used to retrieve and populate the global users view statement
   */
  useEffect(() => {
    (async () => {
      let namespaceList = await getNamespacesData({ role: "user" });
      let user = await getUserInfo();
      getUsersData().then((data: any) => {
        if (data.code === 403) {
          requestGetUsersData();
        } else {
          let userList = data;
          let currentUser = userList.filter(
            (u: any) => u.email === user.email
          )[0];
          if (currentUser.is_admin) {
            console.log("ADMIN");
            let user_data = { role: "admin", email: currentUser.email };
            store.dispatch(setUserInfo(user_data));
          }
          if (namespaceList.namespaces.length === 0) {
            let users_data_response: any = data;
            store.dispatch(setUsersData(users_data_response));
            store.dispatch(setIsPendingRequest(false));
          } else {
            requestGetUsersData();
          }
        }
      });
    })();

    checkUserSession();
    //Check user session every 5 minutes
    const interval = setInterval(() => {
      checkUserSession();
    }, SESSION_CHECK_TIME);

    return () => clearInterval(interval);
  }, []);

  /**
   * useEffect callback on userToken used to refresh user session
   **/
  useEffect(() => {
    if (userToken !== null) {
      let old_session_data: any = getFromStorage(`user_${ID}`);
      const { access_token, id_token, refresh_token, expires_in } = userToken;

      let new_session_data: any = {
        ...old_session_data,
        access_token: access_token,
        refresh_token: refresh_token,
        expire_date: new Date().getTime() + (expires_in - 600) * 1000,
      };

      createCookie(`token_${ID}`, id_token, expires_in * 1000);
      saveToStorage(`user_${ID}`, new_session_data);
    }
  }, [userToken]);

  /**
   * @descr After the login set on the local storage the user role and email data
   */
  useEffect(() => {
    if (userInfo !== null) {
      let session_data: any = getFromStorage(`user_${ID}`);
      //Updating session user data
      let new_session_data: any = {
        ...session_data,
        role: userInfo.role,
        email: userInfo.email,
      };

      saveToStorage(`user_${ID}`, new_session_data);
    }
  }, [userInfo]);

  return (
    <SidebarLayout
      header={<SidebarHeader />}
      subHeader={
        <SidebarSubHeader onClickAddNewChatHandler={onClickAddNewChat} />
      }
      body={
        <SidebarBody
          chats={chatsData}
          onClickAddNewChatHandler={onClickAddNewChat}
          onClickDeleteAllChat={onClickDeleteAllChat}
        />
      }
      footer={<SidebarFooter />}
    >
      {children}
      <UnregistedUserModal
        showModalExpiredSession={showModalUnregisteredUser}
        onDestroySession={onDestroyUserSession}
      />
    </SidebarLayout>
  );
};

export default WrapWithSidebar;
