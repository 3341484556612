import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Button, Loader } from "../../../../core/components/layout";
import {
  NamespacesData,
  UserFilters,
  OnSetSelectedUsers,
  ActiveNamespace,
  SelectedUsers,
  OnSetShowModalNamespaceUsersDeletion,
  OnSetShowFilterUsersSidebar,
  OnAddNamespaceUser,
  OnUpdateNamespaceUsersGrant,
  OnSetActiveNamespace,
  UsersData,
  IsPendingRequest,
  OnSetUserFilters,
} from "../../types";
import { IoSearch } from "react-icons/io5";
import { FaFilter } from "react-icons/fa6";
import { FiFilter } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import InputSearch from "../../../../core/components/form/InputSearch/InputSearch";
//services
import {
  dateToTimestamp,
  timestampToDate,
} from "../../../../core/services/date.services";
import { useTranslation } from "react-i18next";
import { sortJsonByKey } from "../../../../core/services";
import NamespaceUserTable from "./NamespaceUserTable";
import { NamespaceUserData } from "./NamespaceUserType";
import { deleteUserGrantFromNamespace } from "../../sources/services";
import { InputText } from "../../../../core/components/form";
import CustomModal from "../../../../commons/CustomModal";
import { Grid } from "@mui/material";

/* Default existing user form */
const DEFAULT_FORM = {
  user: [],
  user_policy: "user",
};

interface TableNamespaceUsersRoleProps {
  isPendingRequest: IsPendingRequest;
  userFilters: UserFilters;
  namespacesData: NamespacesData;
  usersData: UsersData;
  activeNamespace: ActiveNamespace;
  selectedUsers: SelectedUsers;
  onSetUserFilters: OnSetUserFilters;
  onSetActiveNamespace: OnSetActiveNamespace;
  onSetSelectedUsers: OnSetSelectedUsers;
  onSetShowFilterUsersSidebar: OnSetShowFilterUsersSidebar;
  onSetShowModalNamespaceUsersDeletion: OnSetShowModalNamespaceUsersDeletion;
  onAddNamespaceUser: OnAddNamespaceUser;
  onUpdateNamespaceUsers: OnUpdateNamespaceUsersGrant;
}

const TableNamespaceUsersRoleComponent: React.FC<
  TableNamespaceUsersRoleProps
> = ({
  isPendingRequest,
  userFilters,
  usersData,
  activeNamespace,
  selectedUsers,
  onSetUserFilters,
  onSetActiveNamespace,
  onSetSelectedUsers,
  onSetShowFilterUsersSidebar,
  onSetShowModalNamespaceUsersDeletion,
  onAddNamespaceUser,
  onUpdateNamespaceUsers,
}) => {
  const { t } = useTranslation();
  const form_ref: any = useRef(null);
  const [tableData, setTableData] = useState<any>([]);
  const [dataWithPending, setDataWithPending] = useState<any>([]);
  const [formData, setFormData] = useState<any>(DEFAULT_FORM);
  const [usersOpt, setUsersOpt] = useState<any>([]);
  const [batchDeleteModal, setBatchDeleteModal] = useState<boolean>(false);
  const [itemsToBeDeleted, setItemsToBeDeleted] = useState<NamespaceUserData[]>(
    []
  );
  const [batchDeleteError, setBatchDeleteError] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [roleSwitch, setRoleSwitch] = useState<boolean>(false);
  const [filterSet, setFilterSet] = useState<boolean>(false);

  /**
   * @descr function used to contains the selected user row data
   * @param id user data used to check if it need to be insert or filter from the list
   * @returns list of users data
   */
  const setSelectedUsers = (id: number) => {
    if (selectedUsers.some((item: any) => item.id === id)) {
      let selected_users = selectedUsers.filter((item: any) => item.id !== id);
      return selected_users;
    } else {
      let selected_users = activeNamespace.users.filter(
        (item: any) => item.id === id
      );
      return selectedUsers.concat(selected_users);
    }
  };

  /**
   * @descr function used set the user form data
   * @param {string} field indentify the user form data field
   * @param {any} data form data value
   */
  const onChangeFormHandler = (field: any, data: any) => {
    let form_data = {
      ...formData,
      [field]: data,
    };
    setFormData(form_data);
  };

  /**
   * @descr function used to check track users policy to update
   * @param {any} user_id user data
   * @param {string} policy user policy
   */
  const onChangeUserPolicy = (user_id: any, policy: any) => {
    let table_data = dataWithPending.map((user: any) => {
      if (user.id === user_id && user.policy !== policy) {
        return { ...user, pending_policy: policy };
      }
      if (user.id === user_id && user.policy === policy) {
        return { ...user, pending_policy: null };
      }
      if (user.id !== user_id) return user;
    });

    // setTableData(table_data);
    setDataWithPending(table_data);
  };

  /**
   * @descr Omit a specific field from an object passed as an argument
   * @param key field to omit
   * @param obj target object
   * @returns object without the field identify
   */
  function omit(key: any, obj: any) {
    const { [key]: omitted, ...rest } = obj;
    return rest;
  }

  /**
   * @descr function used to set the advanced filters
   * @param {string} field identify the advanced filter field
   * @param {any} data data to assign to the relative field
   */
  const onChangeAdvancedFilter = (field: any, data: any) => {
    let advanced_fitlers =
      data === ""
        ? omit("email", userFilters)
        : { ...userFilters, [field]: data };
    onSetUserFilters(advanced_fitlers);
    setFilterSet(!filterSet);
  };

  /**
   * @descr function use as callback to add an user on the active namespace grant a base policy to it
   * @param {Object} event click evetn trigger
   */
  const onClickAddUser = (event: any) => {
    event.preventDefault();
    onAddNamespaceUser(formData, activeNamespace);
    if (form_ref.current !== null) form_ref.current.reset();
    setFormData(DEFAULT_FORM);
  };

  /**
   * @descr function used as callback to remove the single (or multiple) user policy from the active namespace
   * @param {Object} event click event trigger
   * @param {Array<any>} users_data selected users data
   */
  const onClickDeleteUser = (event: any, users_data?: any) => {
    event.preventDefault();
    if (users_data !== undefined) {
      const { id } = users_data;
      let selected_users = setSelectedUsers(id);
      onSetShowModalNamespaceUsersDeletion(true, selected_users);
    }
    if (users_data === undefined) {
      onSetShowModalNamespaceUsersDeletion(true);
    }
    onSetShowModalNamespaceUsersDeletion(true);
  };

  /**
   * @descr function used to show the advance users filters modal
   * @param {Object} event click event trigger
   */
  const onClickFilter = (event: any) => {
    event.preventDefault();
    onSetShowFilterUsersSidebar(true);
  };

  /**
   * @descr function used to update the selected users policy
   * @param {Object} event click event trigger
   */
  const onClickConfirm = (event: any) => {
    event.preventDefault();
    onUpdateNamespaceUsers({ ...activeNamespace, users: dataWithPending });
    onSetUserFilters({});
    setRoleSwitch(!roleSwitch);
  };

  /**
   * @descr function used to reset the local form data, advanced filter global state and return to the previews view content.
   * @param {Object} event click event trigger
   */
  const onClickBack = (event: any) => {
    event.preventDefault();
    setFormData(DEFAULT_FORM);
    //onSetFilters({});
    onSetSelectedUsers([]);
    onSetActiveNamespace(null);
    if (form_ref.current !== null) form_ref.current.reset();
  };

  /**
   * @descr useEffect used to handle the selection of the table rows.
   */
  useEffect(() => {
    if (selectedUsers.length > 0) {
      let table_data = tableData.map((item: any) => {
        if (selectedUsers.some((el: any) => item.id === el.id) === true)
          return { ...item, selected: true };
        if (selectedUsers.some((el: any) => item.id === el.id) === false)
          return { ...item, selected: false };
      });
      setTableData(table_data);
    }
    if (selectedUsers.length === 0) {
      let table_data: any = tableData.map((item: any) => {
        return { ...item, selected: false };
      });
      setTableData(table_data);
    }
  }, [selectedUsers]);

  /**
   * @descr useEffect used to handle user addiction filters
   */
  useEffect(() => {
    if (form_ref.current !== null) form_ref.current.reset();
    if (usersData.length > 0 && activeNamespace !== null) {
      if (activeNamespace.users.length > 0) {
        let active_namespace_users_email = activeNamespace.users.map(
          (user: any) => {
            return user.email;
          }
        );
        let diff_users_data = usersData.filter(
          (user: any) => !active_namespace_users_email.includes(user.email)
        );
        setUsersOpt(diff_users_data);
      } else {
        setUsersOpt(usersData);
      }
    }
  }, [activeNamespace, usersData]);

  /**
   * @descr useEffect callback used to filter the data retrive from the service and set the table content
   */
  useEffect(() => {
    if (form_ref.current !== null) form_ref.current.reset();
    if (activeNamespace !== null) {
      const { email, policy, date_start, date_end } = userFilters;
      let table_data = sortJsonByKey(
        activeNamespace.users
          .map((user: any) => {
            return {
              ...user,
              pending_policy: null,
              timestamp: dateToTimestamp(user.creation_date, {
                format: "YYYY-MM-DD",
              }),
            };
          })
          .map((item: any) => {
            if (selectedUsers.some((el: any) => item.id === el.id) === true)
              return { ...item, selected: true };
            if (selectedUsers.some((el: any) => item.id === el.id) === false)
              return { ...item, selected: false };
          })
          .filter((item: any) => {
            if (
              email === "" ||
              email === undefined ||
              item.email.toLowerCase().includes(email.toLowerCase())
            ) {
              return item;
            }
          })
          .filter((item: any) => {
            if (
              policy === undefined ||
              item.policy.toLowerCase().includes(policy[0].toLowerCase())
            ) {
              return item;
            }
          })
          .filter((item: any) => {
            if (
              date_start === undefined ||
              date_start === null ||
              item.timestamp >= date_start
            ) {
              return item;
            }
          })
          .filter((item: any) => {
            if (
              date_end === undefined ||
              date_end === null ||
              item.timestamp <= date_end
            ) {
              return item;
            }
          }),
        "timestamp"
      );
      setTableData(table_data);
      setDataWithPending(table_data);
    }
    if (activeNamespace === null) {
      setTableData([]);
    }
  }, [userFilters, activeNamespace]);

  const batchDelete = useCallback((selected: NamespaceUserData[]) => {
    setBatchDeleteModal(true);
    setItemsToBeDeleted(selected);
  }, []);

  const confirmedBatchDelete = () => {
    setBatchDeleteModal(false);
    let notDeleted: NamespaceUserData[] = [];
    itemsToBeDeleted.every(async (item) => {
      let resp = await deleteUserGrantFromNamespace({
        namespace: item.namespace,
        email: item.email,
        policy: item.policy,
      });
      if (resp !== undefined && resp.code !== undefined) {
        notDeleted.push(item);
      }
      setBatchDeleteModal(false);
      setItemsToBeDeleted([]);
      if (notDeleted.length > 0) {
        setErrors(notDeleted.map((item) => item.email));
        setBatchDeleteError(true);
      } else {
        window.location.reload();
      }
    });
  };

  const clearValue = () => {
    let advanced_fitlers: any = omit("email", userFilters);
    onSetUserFilters(advanced_fitlers);
  };

  return (
    <Container>
      {isPendingRequest && <Loader absolute={true} />}
      {activeNamespace !== null && (
        <React.Fragment>
          {/* Header info */}
          <Row style={{ margin: "0 0 1rem -1rem" }}>
            <div style={{ display: "flex" }}>
              <GoDotFill
                style={{
                  fontSize: 40,
                  color: activeNamespace.icon,
                  margin: "-7px 0 0 0",
                }}
              />
              <h5>
                {" "}
                {`${activeNamespace.name} ${t(
                  "views.namespace.grants.title"
                )} `}{" "}
              </h5>
            </div>
          </Row>

          {/* Users filter */}
          <Row>
            <Grid container>
              <Grid item xs={6}>
                <InputText
                  label=""
                  placeholder={t(
                    "views.namespace.grants.inputFilterPlaceholder"
                  )}
                  headComponent={
                    <IoSearch
                      style={{ color: "#15ccff", marginLeft: ".25rem" }}
                    />
                  }
                  onChangeHandler={(e: any) =>
                    onChangeAdvancedFilter("email", e.target.value)
                  }
                  action={clearValue}
                />
              </Grid>
              <Grid item xs={2} sx={{ marginLeft: "auto" }}>
                <Button
                  variant="light"
                  onClickHandler={onClickFilter}
                  style={{ marginBottom: "1rem" }}
                >
                  <small style={{ fontSize: 14 }}>
                    {t("views.namespace.grants.filterButtonText")}
                  </small>
                  {Object.keys(userFilters).length === 0 && (
                    <FiFilter
                      style={{ marginLeft: ".3rem", marginBottom: ".1rem" }}
                    />
                  )}
                  {Object.keys(userFilters).length > 0 && (
                    <FaFilter
                      style={{ marginLeft: ".3rem", marginBottom: ".1rem" }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Row>

          {/* Add new user inputs */}
          <Form ref={form_ref}>
            <Row>
              <Grid container>
                <Grid item xs={6}>
                  <InputSearch
                    label=""
                    placeholder={t(
                      "views.namespace.grants.inputUserPlaceholder"
                    )}
                    options={usersOpt}
                    labelKey={(user: any) => user.email}
                    selected={formData.user.length > 0 ? formData.user : []}
                    style={{
                      borderStyle: "solid",
                      borderWidth: "2px",
                      borderRadius: 90,
                      borderColor: "#F4F3F3",
                    }}
                    onChangeHandler={(data: any) =>
                      onChangeFormHandler("user", data)
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    disabled={
                      formData.user.length === 0 ||
                      tableData.some(
                        (item: any) => item.pending_policy !== null
                      )
                    }
                    variant={
                      formData.user.length === 0 ||
                      tableData.some(
                        (item: any) => item.pending_policy !== null
                      )
                        ? "secondary"
                        : "primary"
                    }
                    style={{ width: "75%" }}
                    onClickHandler={(e: any) => onClickAddUser(e)}
                  >
                    <small>{t("views.namespace.grants.addButtonText")}</small>
                  </Button>
                </Grid>
              </Grid>
            </Row>
          </Form>

          {/* Table users data */}
          <Row>
            <Col>
              <NamespaceUserTable
                data={tableData}
                batchDelete={batchDelete}
                onClickDeleteUser={onClickDeleteUser}
                onChangeUserPolicy={onChangeUserPolicy}
                roleSwitch={roleSwitch}
                filterSet={filterSet}
              />
            </Col>
          </Row>
          <Row>
            <div style={{ display: "flex", paddingTop: "10px" }}>
              <Button
                variant="secondary"
                outline
                onClickHandler={(e: any) => onClickBack(e)}
                style={{
                  padding: "9px 45px",
                  margin: "0 10px 0 0",
                  color: "#000",
                }}
              >
                <small>{t("views.namespace.grants.backButtonText")}</small>
              </Button>
              <Button
                disabled={
                  !dataWithPending.some(
                    (item: any) => item.pending_policy !== null
                  )
                }
                onClickHandler={(e: any) => onClickConfirm(e)}
                style={{ padding: "9px 45px" }}
              >
                <small>{t("views.namespace.grants.saveButtonText")}</small>
              </Button>
            </div>
          </Row>
        </React.Fragment>
      )}
      <CustomModal
        singleButton={false}
        textList={[
          "views.namespace.grants.modals.delete.bulk",
          itemsToBeDeleted.map((item) => ` ${item.email}`).toString(),
        ]}
        showModal={batchDeleteModal}
        onConfirm={confirmedBatchDelete}
        onCloseModal={() => {
          setBatchDeleteModal(false);
          setItemsToBeDeleted([]);
        }}
      />

      <CustomModal
        singleButton={true}
        button1Text="OK"
        textList={["modal.namespaces.userError", errors.toString()]}
        showModal={batchDeleteError}
        onConfirm={() => {
          setBatchDeleteError(false);
          setErrors([]);
          window.location.reload();
        }}
      />
    </Container>
  );
};

export default TableNamespaceUsersRoleComponent;
