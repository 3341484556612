import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export type DocumentWithStatus = {
  id: number;
  namespace_name: string;
  _id_namespace: string;
  title: string;
  user_id: string;
  state: DocumentStatus;
  start_upload_date: string;
  end_upload_date: string;
};

export type DocumentWithStatusList = {
  documents: DocumentWithStatus[];
};

export interface DocumentWithStatusHeadCell {
  disablePadding: boolean;
  id: keyof DocumentWithStatus;
  label: string;
  numeric: boolean;
  width: string;
  sortable: boolean;
}

export interface TableData {
  _id: number;
  id: number;
  namespace_name: string;
  _id_namespace: string;
  description: string;
  original_link: string;
  title: string;
  user_id: string;
  upload_date: string;
}

export interface DocumentHeadCell {
  disablePadding: boolean;
  id: keyof TableData;
  label: string;
  numeric: boolean;
  width: string;
  sortable: boolean;
}

export type DocumentList = {
  documents: TableData[];
};

export type DocumentStatus = "PROCESSING" | "DONE" | "ERROR";

export type DocumentFilter = {
  title: string | undefined;
  from_date: undefined | string;
  to_date: undefined | string;
  state: DocumentStatus[] | undefined;
  _id_namespaces: string[] | undefined;
  namespaces_names: string[] | undefined;
  users: string[] | undefined;
};

export type DocumentData = {
  description: string | null;
  originalLink: string | null;
  title: string;
  upload_date: string;
  user_id: string;
  _id: string;
  _id_namespace: string;
};

export type DocumentsData = DocumentData[];

export type FilterParams = {
  user_id?: string;
  from_date?: string;
  to_date?: string;
  _id_namespaces?: string[];
  search_text?: string;
};

export type FilterParamsWithStatus = {
  user_id?: string;
  from_date?: string;
  to_date?: string;
  _id_namespaces?: string[];
  title?: string;
  state?: DocumentStatus;
};

export type PaginationParams = {
  page: number;
  page_size: number;
};

export type OrderParams = {
  orderBy: keyof TableData;
  order: "-" | "+";
};

export type OrderParamsWithStatus = {
  orderBy: keyof DocumentWithStatus;
  order: "-" | "+";
};

dayjs.extend(utc);
dayjs.extend(timezone);
export const DEFAULT_FILTER: DocumentFilter = {
  namespaces_names: undefined,
  title: undefined,
  from_date: dayjs()
    .tz("Europe/Rome")
    .subtract(7, "days")
    .startOf("day")
    .format(),
  to_date: dayjs().tz("Europe/Rome").endOf("day").format(),
  state: undefined,
  _id_namespaces: undefined,
  users: undefined,
};
