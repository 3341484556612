import React from "react";
import Form from "react-bootstrap/Form";
//Import button types
import type {
  Label,
  CheckLabel,
  CheckName,
  Value,
  Disabled,
  Required,
  Checked,
  Inline,
  Style,
  ChangeHandler,
} from "./types";

interface InputRadioProps {
  label?: Label;
  checkLabel?: CheckLabel;
  checkName?: CheckName;
  value?: Value;
  disabled?: Disabled;
  required?: Required;
  checked?: Checked;
  inline?: Inline;
  style?: Style;
  onChangeHandler?: ChangeHandler;
}

/*
 * InputRadio component
 */
const InputRadio: React.FC<InputRadioProps> = ({
  label = "",
  checkName = "",
  checkLabel = "Radio element",
  value = "",
  disabled = false,
  required = false,
  checked = false,
  inline = false,
  style = {},
  onChangeHandler = (event: any) => console.log("onChange event fired"),
}) => {
  return (
    <React.Fragment>
      {label !== "" && <Form.Label>{label}</Form.Label>}

      <Form.Check
        type="radio"
        label={checkLabel}
        name={checkName}
        checked={checked}
        disabled={disabled}
        required={required}
        inline={inline}
        defaultValue={value}
        style={style}
        onChange={onChangeHandler}
      />
    </React.Fragment>
  );
};

export default InputRadio;
