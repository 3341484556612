import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import withDocument from "../documents/sources/connectors/documents.connector";
import DocumentsStatusPage from "./DocumentsStatusPage";
import { Namespace } from "../../data-structures/NamespaceType";
import useNamespaceApi from "../../api/NamespaceApi";
import { HcError } from "../../api/ApiErrors";
import WrapWithSidebar from "../../components/WrapWithSidebar";
import CustomModal from "../../commons/CustomModal";

const DocumentsStatusContainer: React.FC = (props: any) => {
  const [namespaces, setNamespaces] = useState<Namespace[]>([]);
  const { getNamespaceListByRole, getNamespaces } = useNamespaceApi();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showInsufficientGrantsModal, setShowInsufficientGrantsModal] =
    useState<boolean>(false);
  const navigate = useNavigate();

  /**
   * useEffect callback used to get documents data
   **/
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const data = await getNamespaceListByRole("supervisor");
      if (data instanceof HcError) {
        setIsLoading(false);
        return;
      }
      if (data.length === 0) {
        setShowInsufficientGrantsModal(true);
        setIsLoading(false);
        return;
      }
      setNamespaces(await getNamespaces(data));
      setIsLoading(false);
    })();
  }, []);

  const handleCloseNonSupervisorModal = () => {
    setShowInsufficientGrantsModal(false);
    navigate("/chat");
  };

  return (
    <WrapWithSidebar>
      <DocumentsStatusPage namespacesData={namespaces} isLoading={isLoading} />
      <CustomModal
        singleButton={true}
        textList={["modal.documents.revocedGrants"]}
        showModal={showInsufficientGrantsModal}
        button1Text="modal.actions.navToHome"
        onConfirm={handleCloseNonSupervisorModal}
      />
    </WrapWithSidebar>
  );
};

export default withDocument(DocumentsStatusContainer);
