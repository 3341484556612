import React, { useEffect } from "react";
import withNamespace from "./sources/connectors/namespace.connector";
import NamespaceContent from "./content/NamespaceContent";
import {
  getNamespacesData,
  getUserInfo,
  getUsersData,
} from "./sources/services";
import { store } from "../../store/app.store";
import { setIsPendingRequest, setUserInfo } from "./sources/namespace.reducer";
import WrapWithSidebar from "../../components/WrapWithSidebar";

const NamespaceContainer: React.FC = (props: any) => {
  useEffect(() => {
    (async () => {
      let namespaceList = await getNamespacesData({ role: "user" });
      let user = await getUserInfo();
      getUsersData().then((data: any) => {
        if (data.code === 403) {
          props.requestGetNamespacesData();
        } else {
          let userList = data;
          let currentUser: any = userList.filter(
            (u: any) => u.email === user.email
          )[0];
          if (currentUser.is_admin && namespaceList.namespaces.length === 0) {
            let user_data = { role: "admin", email: currentUser.email };
            store.dispatch(setUserInfo(user_data));
            store.dispatch(setIsPendingRequest(false));
          }
        }
      });

      if (namespaceList.namespaces.length !== 0) {
        props.requestGetNamespacesData();
      }
    })();
  }, []);

  return (
    <WrapWithSidebar>
      <NamespaceContent {...props} />
    </WrapWithSidebar>
  );
};

export default withNamespace(NamespaceContainer);
