import { Toolbar, alpha, Typography } from "@mui/material";
import { FaRegTrashCan } from "react-icons/fa6";
import { t } from "i18next";
import { Button } from "../../core/components/layout";

interface EnhancedTableToolbarProps {
  numSelected: number;
  handleBatchDelete: () => void;
  numTotal?: number;
}

export default function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, handleBatchDelete, numTotal = 0 } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 && (
        <>
          <Typography variant="body2">
            {`${numSelected} ${t(
              "views.documents.list.table.options.counter"
            )} ${numTotal} ${t("views.documents.list.table.selected")}`}
          </Typography>

          <Button
            variant="primary"
            style={{ marginLeft: "auto" }}
            text={t("views.namespace.list.table.deleteButtonText")}
            size="sm"
            onClickHandler={handleBatchDelete}
          >
            <FaRegTrashCan
              style={{
                fontSize: 14,
                marginLeft: ".3rem",
                marginBottom: ".1rem",
                color: "white",
              }}
            />
          </Button>
        </>
      )}
    </Toolbar>
  );
}
