import React, { useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap";
import { Button } from "../../../../core/components/layout";
import { useTranslation } from "react-i18next";
import {
  createCookie,
  saveToStorage,
  getCookie,
  sha256Encode,
  base64UrlEncode,
} from "../../../../core/services";
import { ID } from "../../../../config";
import {
  REACT_APP_CLIENT_ID,
  REACT_APP_REDIRECT_URL,
  REACT_APP_CODE_VERIFIER,
} from "../../../../custom.config";
import { IsPendingRequest, RequestError, RequestResponse } from "../../types";
import logo from "../../../../core/images/HYPERCHAT_LOGO_1.svg";

const REDIRECT_URL: string = REACT_APP_REDIRECT_URL
  ? REACT_APP_REDIRECT_URL
  : `${window.location.origin}/login`;

interface FormProps {
  isPendingRequest: IsPendingRequest;
  requestError: RequestError;
  requestResponse: RequestResponse;
}

const FormComponent: React.FC<FormProps> = ({
  isPendingRequest,
  requestError,
  requestResponse,
}) => {
  const [redirect, setRedirect] = useState(false);
  const { t } = useTranslation();
  let location = useLocation();

  /**
   * @desc onclick login button function callback
   * @param {object} event, click event
   **/
  const onClickLoginButton = async (event: any) => {
    var code_verifier_hashed = await sha256Encode(REACT_APP_CODE_VERIFIER);
    var code_verifier_hashed_encoded = await base64UrlEncode(
      code_verifier_hashed
    );
    const LOGIN_URL = `https://login.microsoftonline.com/56ac8205-a37e-4856-a53f-686943febf7b/oauth2/v2.0/authorize?client_id=${REACT_APP_CLIENT_ID}&response_type=code&scope=openid%20email%20offline_access&redirect_uri=${REDIRECT_URL}&code_challenge=${code_verifier_hashed_encoded}&code_challenge_method=S256`;
    window.open(LOGIN_URL, "_self");
  };

  /**
   * useEffect callback used to set the cookie session after form submit
   */
  useEffect(() => {
    if (requestResponse !== null) {
      const { access_token, id_token, refresh_token, expires_in } =
        requestResponse;

      let session_data: any = {
        access_token: access_token,
        refresh_token: refresh_token,
        expire_date: new Date().getTime() + (expires_in - 600) * 1000, //remove 10 minutes from the token's expiry time
        role: "",
        username: "",
        email: "",
      };

      let cookie = createCookie(`token_${ID}`, id_token, expires_in * 1000),
        storage = saveToStorage(`user_${ID}`, session_data);

      if (cookie && storage) setRedirect(true);
    }
  }, [requestResponse]);

  /**
   * useEffect callback used to check the cookie session
   */
  useEffect(() => {
    let cookie = getCookie(`token_${ID}`);
    setTimeout(() => {
      if (cookie !== null) setRedirect(true);
    }, 250);
  }, []);

  return (
    <Container>
      {redirect && <Navigate to="/chat" state={{ from: location }} replace />}
      <div
        className="login-container"
        style={{ filter: isPendingRequest ? "blur(5px)" : "" }}
      />
      {isPendingRequest && (
        <Spinner
          className="login-spinner"
          animation="border"
          size="sm"
          variant="primary"
        />
      )}
      <div
        className={"absolute-center half-width"}
        style={{ height: "30vh", bottom: "10rem" }}
      >
        <img
          src={logo}
          className="login-logo"
          alt="HyperChat"
          style={{ filter: isPendingRequest ? "blur(5px)" : "" }}
        />
        {!isPendingRequest && (
          <Button
            text={t("views.login.loginButtonText")}
            style={{ fontWeight: "bold", padding: "5px 40px" }}
            disabled={isPendingRequest}
            onClickHandler={onClickLoginButton}
          />
        )}
      </div>
    </Container>
  );
};

export default FormComponent;
