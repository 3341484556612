import { useEffect, useState } from "react";
import { Container, Grid, Stack, Typography } from "@mui/material";
import { FaFilter, FaListUl } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Button, Loader } from "../../core/components/layout";
import { InputText } from "../../core/components/form";
import { FiFilter } from "react-icons/fi";
import { IoReload, IoSearch } from "react-icons/io5";
import StatusTable from "./StatusTable";
import { Namespace } from "../../data-structures/NamespaceType";
import DocumentsFilters from "./DocumentsFilters";
import {
  DEFAULT_FILTER,
  DocumentFilter,
} from "../../data-structures/DocumentType";
import { useSelector } from "react-redux";
import { RootState } from "../../store/app.store";
import useDebounce from "../../hooks/useDebounce";
import { omit } from "../../utils/utilsFunctions";

type Props = {
  namespacesData: Namespace[];
  isLoading: boolean;
};

export default function DocumentsStatusPage({
  namespacesData,
  isLoading,
}: Props) {
  const [showFilterSidebar, setShowFilterSidebar] = useState(false);
  const [filters, setFilters] = useState<DocumentFilter>(DEFAULT_FILTER);
  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchText = useDebounce(searchText, 500);
  const { t } = useTranslation();
  const uploadedDocuments = useSelector(
    (state: RootState) => state.document.uploadedDocuments
  );

  useEffect(() => {
    if (debouncedSearchText === "") {
      setFilters((prevFilters) => omit("title", prevFilters));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        title: debouncedSearchText,
      }));
    }
  }, [debouncedSearchText]);

  const onFiltersSet = (advancedFilters: DocumentFilter) => {
    setFilters(advancedFilters);
  };

  const onSearchTitle = (event: any, field: string) => {
    setSearchText(event.target.value);
  };

  const clearValue = () => {
    setSearchText("");
  };

  const clearFilters = () => {
    setFilters({ ...DEFAULT_FILTER, title: searchText });
  };

  const onClickRefresh = () => {
    setFilters({ ...filters });
  };

  return (
    <Container maxWidth={false} sx={{ marginRight: "60px" }}>
      <Stack p={3} spacing={5} sx={{ height: "100%" }}>
        <Typography variant="h3" sx={{ fontSize: "1.3rem" }}>
          <FaListUl
            style={{
              marginRight: ".5rem",
              marginBottom: ".2rem",
              fontSize: 18,
            }}
          />
          {t("views.documents.status.title")}
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <InputText
              label=""
              placeholder={t("views.documents.list.inputPlaceholder")}
              value=""
              headComponent={
                <IoSearch style={{ color: "#15ccff", marginLeft: ".25rem" }} />
              }
              onChangeHandler={(event: any) => onSearchTitle(event, "title")}
              action={clearValue}
            />
          </Grid>
          <Grid item xs={2} sx={{ marginLeft: "auto" }}>
            <Button
              variant="light"
              onClickHandler={() => setShowFilterSidebar(true)}
              style={{ marginBottom: "1rem" }}
            >
              <Typography variant="body1">
                {t("views.documents.list.filterButtonText")}
              </Typography>
              {Object.keys(filters).length === 0 && (
                <FiFilter
                  style={{ marginLeft: ".3rem", marginBottom: ".1rem" }}
                />
              )}
              {Object.keys(filters).length > 0 && (
                <FaFilter
                  style={{ marginLeft: ".3rem", marginBottom: ".1rem" }}
                />
              )}
            </Button>
          </Grid>
        </Grid>

        {isLoading ? (
          <Loader absolute={true} />
        ) : (
          <StatusTable
            filters={filters}
            data={uploadedDocuments}
            namespacesData={namespacesData}
          />
        )}
        <Grid container sx={{ marginTop: "auto!important" }}>
          <Grid item xs={6}>
            <Button
              text={t("views.documents.status.refresh")}
              style={{ padding: "9px 45px" }}
              onClickHandler={onClickRefresh}
            >
              <IoReload
                style={{
                  fontSize: 14,
                  marginLeft: ".3rem",
                  marginBottom: ".1rem",
                  color: "white",
                }}
              />
            </Button>
          </Grid>
        </Grid>
      </Stack>
      <DocumentsFilters
        showFilterSidebar={showFilterSidebar}
        documentsData={uploadedDocuments}
        namespacesData={namespacesData}
        filters={filters}
        onSetFilters={onFiltersSet}
        onCloseFilters={() => setShowFilterSidebar(false)}
        resetFilters={clearFilters}
      />
    </Container>
  );
}
