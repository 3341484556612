import React, { useState, useEffect } from "react";
import { Row, Col, Offcanvas } from "react-bootstrap";
import dayjs from "dayjs";
import Calendar from "react-calendar";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { BsCalendar4 } from "react-icons/bs";
import { CgCloseO } from "react-icons/cg";
import { FaRegUser } from "react-icons/fa";
import { GrStatusGood } from "react-icons/gr";
import { LuAtom } from "react-icons/lu";
import { Namespace } from "../../data-structures/NamespaceType";
import { Button, Loader } from "../../core/components/layout";
import InputSearch from "../../core/components/form/InputSearch/InputSearch";
import {
  DEFAULT_FILTER,
  DocumentFilter,
} from "../../data-structures/DocumentType";
import useDocumentsApi from "../../api/DocumentsApi";

type Props = {
  filters: DocumentFilter;
  documentsData: any;
  showFilterSidebar: boolean;
  namespacesData: Namespace[];
  onSetFilters: (advancedFilters: any) => void;
  onCloseFilters: () => void;
  resetFilters: () => void;
};

const SidebarFiltersComponent: React.FC<Props> = ({
  filters,
  documentsData,
  showFilterSidebar,
  namespacesData,
  onSetFilters,
  onCloseFilters,
  resetFilters,
}) => {
  const [advancedFilter, setAdvancedFilter] = useState<DocumentFilter>(filters);
  const [namespaces, setNamespaces] = useState<string[]>([]);
  const [users, setUsers] = useState<string[]>([]);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { getUserOnDocumentList } = useDocumentsApi();

  /**
   * @descr Omit a specific field from an object passed as an argument
   * @param key field to omit
   * @param obj target object
   * @returns object without the field identify
   */
  function omit(key: any, obj: any) {
    const { [key]: omitted, ...rest } = obj;
    return rest;
  }

  /**
   * @desc function used to update the filters using search inputs
   * @param {object} data, filter data value
   * @param {string} field, filter item string value
   **/
  const onChangeAdvancedFilter = (data: any, field: string) => {
    if (data.length === 0) {
      if (field === "namespaces_names") {
        getUserOnDocumentList([]).then((users) => setUsers(users.users));
        let advanced_fitlers: any = omit("_id_namespaces", advancedFilter);
        let cleaned_filters: any = omit(field, advanced_fitlers);
        setAdvancedFilter(cleaned_filters);
      } else {
        let advanced_fitlers: any = omit(field, advancedFilter);
        setAdvancedFilter(advanced_fitlers);
      }
    }
    if (data.length > 0) {
      let advanced_fitlers: any;

      if (field === "namespaces_names") {
        let ns_ids = namespacesData
          .filter((ns: any) => data.includes(ns.name))
          .map((ns: Namespace) => ns._id);
        getUserOnDocumentList(ns_ids).then((users) => setUsers(users.users));
        advanced_fitlers = {
          ...advancedFilter,
          [field]: data,
          ["_id_namespaces"]: ns_ids,
        };
      } else if (field === "state") {
        let status = data.map((s: string) => {
          if (s === "In lavorazione" || s === "Processing") return "PROCESSING";
          if (s === "Processato" || s === "Done") return "DONE";
          if (s === "Errore" || s === "Error") return "ERROR";
        });
        advanced_fitlers = {
          ...advancedFilter,
          [field]: status,
        };
      } else {
        advanced_fitlers = {
          ...advancedFilter,
          [field]: data,
        };
      }
      setAdvancedFilter(advanced_fitlers);
    }
  };

  /**
   * @desc function used to update the filters using calendar component
   * @param {object} data, filter data value
   * @param {string} field, filter item string value
   **/
  const onChangeAdvancedDateFilter = (data: any, field: string) => {
    let date: string;
    if (field === "from_date") {
      date = dayjs(data.getTime()).tz("Europe/Rome").startOf("day").format();
    } else {
      date = dayjs(data.getTime()).tz("Europe/Rome").endOf("day").format();
    }
    let advanced_fitlers = { ...advancedFilter, [field]: date };
    setAdvancedFilter(advanced_fitlers);
  };

  /**
   * @desc function used to save filter object
   **/
  const onClickConfirm = () => {
    onSetFilters(advancedFilter);
    onCloseFilters();
  };

  /**
   * @desc function used to reset filter object
   **/
  const onClickReset = () => {
    setAdvancedFilter(DEFAULT_FILTER);
    resetFilters();
    onCloseFilters();
  };

  /**
   * useEffect callback used to set filters
   **/
  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      setAdvancedFilter(filters);
      if (filters._id_namespaces !== undefined)
        getUserOnDocumentList(filters._id_namespaces).then((users) =>
          setUsers(users.users)
        );
    }
    if (Object.keys(filters).length === 0) {
      setAdvancedFilter(DEFAULT_FILTER);
      getUserOnDocumentList([""]).then((users) => setUsers(users.users));
    }
  }, [filters]);

  /**
   * useEffect callback used to set users and namespaces options from documents data
   **/
  useEffect(() => {
    setIsLoading(true);
    let namespaces_names = namespacesData.map((ns: any) => ns.name);
    getUserOnDocumentList(filters._id_namespaces).then((users) =>
      setUsers(users.users)
    );
    setNamespaces(namespaces_names);
    setIsLoading(false);
  }, [documentsData, showFilterSidebar, namespacesData]);

  return (
    <Offcanvas
      placement="end"
      show={showFilterSidebar}
      style={{ zIndex: 99999 }}
      onHide={onCloseFilters}
    >
      {isLoading && <Loader absolute={true} />}
      <Row
        style={{
          margin: "0 0.15rem",
          padding: "1rem 0 0.5rem 0",
          borderBottom: "1px solid #CCC",
        }}
      >
        <Col>
          <h6>
            <b>{t("views.documents.list.sidebar.title")}</b>
          </h6>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Button
            onClickHandler={onCloseFilters}
            variant="light"
            style={{ border: "none" }}
          >
            <CgCloseO style={{ fontSize: 20 }} />
          </Button>
        </Col>
      </Row>
      {/* Filters inputs */}
      <Scrollbars style={{ height: "calc(100vh - 115px)" }} autoHide={true}>
        <Row style={{ margin: "15px 0 5px 15px" }}>
          <Col>
            <small>
              <LuAtom style={{ marginRight: ".3rem" }} />
              <b>{t("views.documents.list.sidebar.namespaceInputLabel")}</b>
            </small>
          </Col>
        </Row>
        <Row style={{ margin: "0 15px" }}>
          <Col>
            <InputSearch
              label=""
              placeholder={t(
                "views.documents.list.sidebar.namespaceInputPlaceholder"
              )}
              options={namespaces}
              selected={advancedFilter.namespaces_names}
              multiple={true}
              onChangeHandler={(item: any) =>
                onChangeAdvancedFilter(item, "namespaces_names")
              }
            />
          </Col>
        </Row>
        <Row style={{ margin: "0 0 10px 15px" }}>
          <Col>
            <small>
              <GrStatusGood style={{ marginRight: ".3rem" }} />
              <b>{t("views.documents.list.sidebar.statusInputLabel")}</b>
            </small>
          </Col>
        </Row>
        <Row style={{ margin: "0 15px" }}>
          <Col>
            <InputSearch
              label=""
              placeholder={t(
                "views.documents.list.sidebar.statusInputPlaceholder"
              )}
              options={[
                `${t("views.documents.status.PROCESSING")}`,
                `${t("views.documents.status.DONE")}`,
                `${t("views.documents.status.ERROR")}`,
              ]}
              selected={advancedFilter.state?.map((s) =>
                t(`views.documents.status.${s}`)
              )}
              multiple={false}
              onChangeHandler={(item: any) =>
                onChangeAdvancedFilter(item, "state")
              }
            />
          </Col>
        </Row>
        <Row style={{ margin: "0 0 10px 15px" }}>
          <Col>
            <small>
              <FaRegUser style={{ marginRight: ".3rem" }} />
              <b>{t("views.documents.list.sidebar.userInputLabel")}</b>
            </small>
          </Col>
        </Row>
        <Row style={{ margin: "0 15px" }}>
          <Col>
            <InputSearch
              label=""
              placeholder={t(
                "views.documents.list.sidebar.userInputPlaceholder"
              )}
              options={users}
              selected={advancedFilter.users}
              multiple={true}
              onChangeHandler={(item: any) =>
                onChangeAdvancedFilter(item, "users")
              }
            />
          </Col>
        </Row>
        <Row style={{ margin: "0 0 0 15px" }}>
          <Col>
            <small style={{ fontSize: 14 }}>
              <BsCalendar4 style={{ marginRight: ".3rem", fontSize: 14 }} />
              <b>{t("views.documents.list.sidebar.dateFromLabel")} </b>

              {dayjs(advancedFilter.from_date).format("YYYY-MM-DD") !== null
                ? `${dayjs(advancedFilter.from_date).format("YYYY-MM-DD")}`
                : ""}
            </small>
          </Col>
        </Row>
        <Row style={{ margin: "0 0 15px 0" }}>
          <Col>
            <div style={{ transform: "scale(0.8)" }}>
              <Calendar
                value={advancedFilter.from_date}
                onChange={(e: any) =>
                  onChangeAdvancedDateFilter(e, "from_date")
                }
              />
            </div>
          </Col>
        </Row>
        <Row style={{ margin: "0 15px" }}>
          <Col>
            <small style={{ fontSize: 14 }}>
              <BsCalendar4 style={{ marginRight: ".3rem", fontSize: 14 }} />
              <b>{t("views.documents.list.sidebar.dateToLabel")} </b>
              {dayjs(advancedFilter.to_date).format("YYYY-MM-DD") !== null
                ? `${dayjs(advancedFilter.to_date).format("YYYY-MM-DD")}`
                : ""}
            </small>
          </Col>
        </Row>
        <Row style={{ margin: "0 0 15px 0" }}>
          <Col>
            <div style={{ transform: "scale(0.8)" }}>
              <Calendar
                value={advancedFilter.to_date}
                onChange={(e: any) => onChangeAdvancedDateFilter(e, "to_date")}
              />
            </div>
          </Col>
        </Row>
      </Scrollbars>

      {/** Footer buttons */}
      <Row style={{ marginTop: "15px" }}>
        <Col>
          <Button
            text={t("views.documents.list.sidebar.backButtonText")}
            variant="secondary"
            style={{
              color: "#000",
              width: "45%",
              marginLeft: "3%",
              marginRight: "2%",
            }}
            onClickHandler={onClickReset}
          />
          <Button
            text={t("views.documents.list.sidebar.confirmButtonText")}
            variant="primary"
            style={{ width: "45%", marginLeft: "2%" }}
            onClickHandler={onClickConfirm}
          />
        </Col>
      </Row>
    </Offcanvas>
  );
};

export default SidebarFiltersComponent;
