import { Box } from "@mui/material";
import { Loader } from "../../core/components/layout";

interface Props {
  height: number | string;
  show: boolean;
  animation?: boolean;
}
const TableLoader = ({ height, show, animation = true }: Props) => {
  if (!show) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: height,
      }}
    >
      {animation && <Loader absolute={false} />}
    </Box>
  );
};

export default TableLoader;
