import React from "react";
import { Row, Col, Container, Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getFromStorage } from "../core/services";
import { ID } from "../config";
import { FaListUl, FaRegFolderOpen, FaUserPlus } from "react-icons/fa";
import { GrStatusGood } from "react-icons/gr";
import { VscNewFile } from "react-icons/vsc";
import { BsFileEarmarkCheck } from "react-icons/bs";
import { LuAtom } from "react-icons/lu";
import { NavLink } from "react-router-dom";
import { useAppDispatch } from "../store/app.hooks";
import { setActiveUser } from "../views/users/sources/users.reducer";
import { setActiveNamespace } from "../views/namespace/sources/namespace.reducer";

const DOCUMENT_MANAGEMENT_AUTH: any = ["SUPERVISOR", "ADMIN"];
const USER_PERMISSION_MANAGEMENTE_AUTH: any = ["ADMIN"];

type SidebarFooterProps = {
  icon: React.ReactNode;
  text: string;
  link: string;
};

const SidebarFooterRow = ({ icon, link, text }: SidebarFooterProps) => {
  const dispatch = useAppDispatch();
  // reset page content
  const handleClick = () => {
    if (link === "/namespaces") {
      dispatch(setActiveNamespace(null));
    }
    if (link === "/users") {
      dispatch(setActiveUser(null));
    }
  };
  return (
    <Row style={{ marginBottom: "0.5rem" }}>
      <Col className="text-start" style={{ marginLeft: "22px" }}>
        <NavLink
          to={link}
          style={{ color: "white", textDecoration: "none" }}
          onClick={handleClick}
        >
          {icon}
          <small style={{ marginLeft: ".5rem" }}>{text}</small>
        </NavLink>
      </Col>
    </Row>
  );
};

const SidebarFooter: React.FC = () => {
  const { t } = useTranslation();
  const user_data = getFromStorage(`user_${ID}`);

  return (
    <Container>
      <Row>
        <Col>
          {(DOCUMENT_MANAGEMENT_AUTH.includes(user_data.role.toUpperCase()) ||
            USER_PERMISSION_MANAGEMENTE_AUTH.includes(
              user_data.role.toUpperCase()
            )) && (
            <Accordion className="sidebar-body-accordion" flush={true}>
              {DOCUMENT_MANAGEMENT_AUTH.includes(
                user_data.role.toUpperCase()
              ) && (
                <Accordion.Item eventKey={"0"} key={"0"}>
                  <Accordion.Header>
                    <div style={{ fontSize: 16 }}>
                      <FaRegFolderOpen />
                      <small style={{ marginLeft: ".5rem" }}>
                        {" "}
                        {t(`sidebar.documents.title`)}{" "}
                      </small>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body style={{ color: "#FFF" }}>
                    <SidebarFooterRow
                      icon={
                        <VscNewFile
                          style={{ fontSize: 16, marginTop: "-.3rem" }}
                        />
                      }
                      link="/documents?add=true"
                      text={` ${t(`sidebar.documents.add`)} `}
                    />
                    <SidebarFooterRow
                      icon={
                        <FaListUl
                          style={{ fontSize: 16, marginTop: "-.3rem" }}
                        />
                      }
                      link="/documents"
                      text={` ${t(`sidebar.documents.list`)} `}
                    />
                    <SidebarFooterRow
                      icon={
                        <GrStatusGood
                          style={{ fontSize: 16, marginTop: "-.3rem" }}
                        />
                      }
                      link="/documents/status"
                      text={` ${t("sidebar.documents.status")} `}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              )}
              {USER_PERMISSION_MANAGEMENTE_AUTH.includes(
                user_data.role.toUpperCase()
              ) && (
                <Accordion.Item eventKey={"1"}>
                  <Accordion.Header>
                    <div style={{ fontSize: 16 }}>
                      <BsFileEarmarkCheck />
                      <small style={{ marginLeft: ".5rem" }}>
                        {" "}
                        {t(`sidebar.userNamespace.title`)}{" "}
                      </small>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body style={{ color: "#FFF" }}>
                    <SidebarFooterRow
                      icon={<LuAtom style={{ fontSize: 16 }} />}
                      link="/namespaces"
                      text={` ${t(`sidebar.userNamespace.namespaces`)} `}
                    />
                    <SidebarFooterRow
                      icon={<FaUserPlus style={{ fontSize: 16 }} />}
                      text={` ${t(`sidebar.userNamespace.users`)} `}
                      link="/users"
                    />
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SidebarFooter;
