import { Suspense } from "react";
import Router from "./Router";
import "../core/style/App.scss";
import { Provider } from "react-redux";
import { store } from "../store/app.store";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <Suspense>
        <Router />
      </Suspense>
    </Provider>
  );
}

export default App;
