import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Button, Loader } from "../../../../core/components/layout";
import { InputText } from "../../../../core/components/form";
import {
  Filters,
  OnAddUser,
  OnSetActiveUser,
  OnSetFilters,
  OnSetSelectedUsers,
  OnSetShowFilterSidebar,
  OnSetShowModalUsersDeletion,
  SelectedUsers,
  UsersData,
  IsPendingRequest,
} from "../../types";
import { IoSearch } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa6";
import { FiUserPlus } from "react-icons/fi";
// services
import {
  dateToTimestamp,
  sortJsonByKey,
  trimStr,
} from "../../../../core/services";
import { useTranslation } from "react-i18next";
import UsersTable from "./UsersTable";
import { UserData } from "./UserDataType";
import { deleteUser } from "../../sources/services";
import CustomModal from "../../../../commons/CustomModal";
import { Grid } from "@mui/material";

const DEFAULT_FORM = {
  email: "",
};

interface TableUsersProps {
  isPendingRequest: IsPendingRequest;
  usersData: UsersData;
  selectedUsers: SelectedUsers;
  filters: Filters;
  // namespacesData: NamespacesData,
  onAddUser: OnAddUser;
  onSetFilters: OnSetFilters;
  onSetSelectedUsers: OnSetSelectedUsers;
  onSetShowFilterSidebar: OnSetShowFilterSidebar;
  onSetShowModalUsersDeletion: OnSetShowModalUsersDeletion;
  onSetActiveUser: OnSetActiveUser;
}

const TableUsersComponent: React.FC<TableUsersProps> = ({
  isPendingRequest,
  usersData,
  selectedUsers,
  filters,
  // namespacesData,
  onAddUser,
  onSetFilters,
  onSetSelectedUsers,
  onSetShowFilterSidebar,
  onSetShowModalUsersDeletion,
  onSetActiveUser,
}) => {
  const { t } = useTranslation();
  const form_ref: any = useRef(null);
  const [tableData, setTableData] = useState<any>([]);
  const [formData, setFormData] = useState<any>(DEFAULT_FORM);
  const [batchDeleteModal, setBatchDeleteModal] = useState<boolean>(false);
  const [itemsToBeDeleted, setItemsToBeDeleted] = useState<UserData[]>([]);
  const [batchDeleteError, setBatchDeleteError] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const batchDelete = useCallback((selected: UserData[]) => {
    setBatchDeleteModal(true);
    setItemsToBeDeleted(selected);
  }, []);

  const confirmedBatchDelete = async () => {
    setBatchDeleteModal(false);
    let notDeleted: UserData[] = [];
    for (const item of itemsToBeDeleted) {
      let resp = await deleteUser({
        email: item.email,
      });
      if (resp !== undefined && resp.code !== undefined) {
        notDeleted.push(item);
      }
    }
    setItemsToBeDeleted([]);
    if (notDeleted.length > 0) {
      setErrors(notDeleted.map((item) => item.email));
      setBatchDeleteError(true);
    } else {
      window.location.reload();
    }
  };

  /**
   * Table options
   */
  const paginationComponentOptions = {
    rowsPerPageText: t("views.users.list.table.options.pagination"),
    rangeSeparatorText: t("views.users.list.table.options.counter"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("views.users.list.table.options.all"),
  };

  /**
   * @desc function used to set selected users from table (used for deletion)
   * @param {number} id, document id
   **/
  const setSelectedUsers = (id: number) => {
    if (selectedUsers.some((item: any) => item.id === id)) {
      let selected_users = selectedUsers.filter((item: any) => item.id !== id);
      return selected_users;
    } else {
      let selected_users = usersData.filter((item: any) => item.id === id);
      return selectedUsers.concat(selected_users);
    }
  };

  /**
   * @descr The selection of individual rows has 2 roles: The first is to keep track of document data that could be deleted within a local state (selectedData).
   *        The second is to modify the "selected" field responsible for changing the style used by the table props (conditionalRowStyles)
   * @param {Object} row The relative table row data.
   */
  const onClickSelectRow = (row: any) => {
    const { id } = row;
    let selected_users = setSelectedUsers(id);
    onSetSelectedUsers(selected_users);
  };

  /**
   * @descr function used as callback to add a new user, than reset the form data.
   * @param {Object} event click event trigger
   */
  const onClickAddUser = (event: any) => {
    event.preventDefault();
    if (form_ref.current !== null) form_ref.current.reset();

    let form_data = {
      email: trimStr(formData.email),
    };

    onAddUser(form_data);
    setFormData(DEFAULT_FORM);
  };

  /**
   * @descr function used as callback to delete single (or multiple) selected user.
   * @param {Object} event click event trigger
   * @param {Array<Object>} users_data selected user/users data
   */
  const onClickDeleteUser = (event: any, users_data?: any) => {
    event.preventDefault();
    if (users_data !== undefined) {
      const { id } = users_data;
      let selected_users = setSelectedUsers(id);
      onSetShowModalUsersDeletion(true, selected_users);
    }
    if (users_data === undefined) {
      onSetShowModalUsersDeletion(true);
    }
    onSetShowModalUsersDeletion(true);
  };

  /**
   * @descr fucntion used as callback to set the selected user details
   * @param {Object} event click event trigger
   * @param {Object} user_data selected user data
   */
  const onClickDetail = (event: any, user_data: any) => {
    event.preventDefault();
    onSetActiveUser(user_data);
  };

  /**
   * @descr function used as callback to set the advanced filters object
   * @param {string} field identify the filters object field
   * @param {any} data value of the field
   */
  const onChangeAdvancedFilter = (field: any, data: any) => {
    let advanced_fitlers = { ...filters, [field]: data };
    onSetFilters(advanced_fitlers);
  };

  // const onClickFilter = (event:any) => {
  //     event.preventDefault();
  //     onSetShowFilterSidebar(true);
  // }

  /**
   * @descr function used to set the local form data object
   * @param {string} field identify the form data field
   * @param {any} data value of the field
   */
  const onChangeFormHandler = (field: any, data: any) => {
    let form_data = {
      ...formData,
      [field]: data,
    };
    setFormData(form_data);
  };

  /**
   * @descr useEffect callback used to highlight selected rows of the table
   */
  useEffect(() => {
    if (selectedUsers.length > 0) {
      let table_data = tableData.map((item: any) => {
        if (selectedUsers.some((el: any) => item.id === el.id) === true)
          return { ...item, selected: true };
        if (selectedUsers.some((el: any) => item.id === el.id) === false)
          return { ...item, selected: false };
      });
      setTableData(table_data);
    }
    if (selectedUsers.length === 0) {
      let table_data: any = tableData.map((item: any) => {
        return { ...item, selected: false };
      });
      setTableData(table_data);
    }
  }, [selectedUsers]);

  /**
   * @descr useEffect callback used to filter the data retrive from the service and set the table content
   */
  useEffect(() => {
    if (form_ref.current !== null) form_ref.current.reset();
    let table_data: any = sortJsonByKey(
      usersData
        .map((item: any, index: any) => {
          return {
            ...item,
            timestamp: dateToTimestamp(item.creation_date, {
              format: "YYYY-MM-DD",
            }),
            selected: false,
          };
        })
        .map((item: any) => {
          if (selectedUsers.some((el: any) => item.id === el.id) === true)
            return { ...item, selected: true };
          if (selectedUsers.some((el: any) => item.id === el.id) === false)
            return { ...item, selected: false };
        })
        .filter((item: any) => {
          if (
            filters.email === "" ||
            filters.email === undefined ||
            item.email.toLowerCase().includes(filters.email.toLowerCase())
          ) {
            return item;
          }
        }),
      "timestamp"
    );

    setTableData(table_data);
  }, [usersData, filters]);

  function omit(key: any, obj: any) {
    const { [key]: omitted, ...rest } = obj;
    return rest;
  }

  const clearValue = () => {
    let advanced_fitlers: any = omit("email", filters);
    onSetFilters(advanced_fitlers);
  };

  return (
    <Container>
      {isPendingRequest && <Loader absolute={true} />}
      {/* Header info */}
      <Row style={{ margin: "0 0 1rem -1rem" }}>
        <div style={{ display: "flex" }}>
          <h5 style={{ fontSize: "1.3rem" }}>
            {" "}
            <FaRegUser style={{ fontSize: 16, margin: "-5px 0 0 0" }} />{" "}
            {t("views.users.list.title")}{" "}
          </h5>
        </div>
      </Row>

      {/* Users filter */}
      <Row>
        <Grid container>
          <Grid item xs={6}>
            <InputText
              label=""
              value={filters.email !== undefined ? filters.email : ""}
              placeholder={t("views.users.list.inputFilterPlaceholder")}
              headComponent={
                <IoSearch style={{ color: "#15ccff", marginLeft: ".25rem" }} />
              }
              onChangeHandler={(e: any) =>
                onChangeAdvancedFilter("email", e.target.value)
              }
              action={clearValue}
            />
          </Grid>
        </Grid>
      </Row>

      {/* Add new user inputs */}
      <Form ref={form_ref} onSubmit={(e: any) => onClickAddUser(e)}>
        <Row>
          <Grid container>
            <Grid item xs={6}>
              <InputText
                label=""
                placeholder={t("views.users.list.inputUserPlaceholder")}
                headComponent={
                  <FiUserPlus
                    style={{
                      color: "#15ccff",
                      marginLeft: ".3rem",
                      fontSize: 14,
                    }}
                  />
                }
                onChangeHandler={(e: any) =>
                  onChangeFormHandler("email", e.target.value)
                }
                action={() => setFormData(DEFAULT_FORM)}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                type={"submit"}
                variant={
                  formData.email.length === 0 ||
                  trimStr(formData.email).length === 0
                    ? "secondary"
                    : "primary"
                }
                disabled={
                  formData.email.length === 0 ||
                  trimStr(formData.email).length === 0
                }
                style={{ width: "75%" }}
              >
                <small>{t("views.users.list.userButtonText")}</small>
              </Button>
            </Grid>
          </Grid>
        </Row>
      </Form>
      {/* Table users data */}
      <Row>
        <Col>
          <UsersTable
            data={tableData}
            onClickDeleteUser={onClickDeleteUser}
            onClickDetail={onClickDetail}
            batchDelete={batchDelete}
          />
        </Col>
      </Row>

      <CustomModal
        singleButton={false}
        textList={[
          "views.users.list.modals.delete.bulk",
          itemsToBeDeleted.map((item) => ` ${item.email}`).toString(),
        ]}
        showModal={batchDeleteModal}
        onConfirm={confirmedBatchDelete}
        onCloseModal={() => {
          setBatchDeleteModal(false);
          setItemsToBeDeleted([]);
        }}
      />

      <CustomModal
        singleButton={true}
        button1Text="OK"
        textList={["modal.users.error", errors.toString()]}
        showModal={batchDeleteError}
        onConfirm={() => {
          setBatchDeleteError(false);
          setErrors([]);
          window.location.reload();
        }}
      />
    </Container>
  );
};

export default TableUsersComponent;
