import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Loader } from "../../../../core/components/layout";
import { InputText } from "../../../../core/components/form";
import { Form, Col, Row } from "react-bootstrap";
import InputTextarea from "../../../../core/components/form/InputTextArea/InputTextArea";
import { trimStr } from "../../../../core/services";
import { useTranslation } from "react-i18next";
import {
  ActiveDocument,
  ShowModalDocumentDescription,
  OnSetShowModalDocumentDescription,
  OnUpdateDocument,
  IsPendingRequest,
  DocumentsDataConflicts,
} from "../../types";
import { CiFileOn } from "react-icons/ci";
import { AiOutlineExport } from "react-icons/ai";
import styles from "../../../../core/style/global/global.module.scss";

interface ModalDocumentsDescriprionProps {
  isPendingRequest: IsPendingRequest;
  activeDocument: ActiveDocument;
  documentsDataConflicts: DocumentsDataConflicts;
  showModalDocumentDescription: ShowModalDocumentDescription;
  onUpdateDocument: OnUpdateDocument;
  onSetShowModalDocumentDescription: OnSetShowModalDocumentDescription;
}

const MAX_DESCRIPTION_LENGTH: number = 256;

// Default document data
const DOCUMENT_DEFAULT_DATA: any = {
  title: "",
  description: "",
};

const ModalDocumentsDescriptionComponent: React.FC<
  ModalDocumentsDescriprionProps
> = ({
  isPendingRequest,
  activeDocument,
  documentsDataConflicts,
  showModalDocumentDescription,
  onUpdateDocument,
  onSetShowModalDocumentDescription,
}) => {
  const form_ref: any = useRef(null);
  const [formData, setFormData] = useState<any>(DOCUMENT_DEFAULT_DATA);
  const { t } = useTranslation();

  /**
   * useEffect callback used to set form data when a document is selected from table
   **/
  useEffect(() => {
    if (form_ref.current !== null) form_ref.current.reset();
    if (activeDocument !== null)
      setFormData({ ...formData, ...activeDocument });
    if (activeDocument === null) setFormData(DOCUMENT_DEFAULT_DATA);
  }, [activeDocument]);

  /**
   * @desc function used to update the metadata info of a document
   * @param {object} event, input change event value
   * @param {string} field, filter string value
   **/
  const onChangeInputText = (event: any, field: string) => {
    const {
      target: { value },
    } = event;

    let form_data = {
      ...formData,
      [field]: value.trim(),
    };
    setFormData(form_data);
  };

  const dataUpdated =
    activeDocument &&
    ((formData.new_title && formData.new_title !== activeDocument.title) ||
      (formData.new_link &&
        formData.new_link !== activeDocument.original_link) ||
      (formData.new_description &&
        formData.new_description !== activeDocument.description));

  const submitDisabled =
    isPendingRequest || formData.new_title === "" || !dataUpdated;

  /**
   * @desc function used as callback of close modal event, useful to reset the component state
   **/
  const onCloseModal = () => {
    if (!isPendingRequest) onSetShowModalDocumentDescription(false);
    if (form_ref.current !== null) form_ref.current.reset();
    setFormData(DOCUMENT_DEFAULT_DATA);
  };

  /**
   * @desc OnFormSubmit callback used to save vehicle data
   * @param {object} event, form submit event data
   **/
  const onFormSubmit = (event: any) => {
    event.preventDefault();
    if (formData.new_title === "") return;

    onUpdateDocument(formData);
  };

  return (
    <Modal
      visible={showModalDocumentDescription}
      size="lg"
      title={t("views.documents.list.modals.update.title")}
      onHideHandler={onCloseModal}
    >
      <Form onSubmit={onFormSubmit} ref={form_ref}>
        {isPendingRequest && <Loader absolute={true} />}
        <Row>
          <Col
            lg={1}
            md={2}
            sm={2}
            className="text-end"
            style={{ paddingTop: "1.8rem" }}
          >
            <CiFileOn style={{ fontSize: 18 }} />
          </Col>
          <Col>
            <InputText
              label={t("views.documents.list.modals.update.filenameInputLabel")}
              placeholder={t(
                "views.documents.list.modals.update.filenameInputPlaceholder"
              )}
              style={{
                color:
                  documentsDataConflicts.filter(
                    (item: any) =>
                      item.document_new_title === formData.new_title
                  ).length > 0
                    ? styles.danger
                    : styles.dark,
              }}
              value={formData.title}
              action={() =>
                onChangeInputText({ target: { value: "" } }, "new_title")
              }
              onChangeHandler={(e: any) => onChangeInputText(e, "new_title")}
              isInvalid={formData.new_title === ""}
            />
          </Col>
        </Row>
        <Row>
          <Col
            lg={1}
            md={2}
            sm={2}
            className="text-end"
            style={{ paddingTop: "1.8rem" }}
          >
            <AiOutlineExport style={{ fontSize: 16 }} />
          </Col>
          <Col>
            <InputText
              label={t("views.documents.list.modals.update.fileUrlInputLabel")}
              placeholder={t(
                "views.documents.list.modals.update.fileUrlInputPlaceholder"
              )}
              value={
                formData.new_link === undefined || formData.new_link === null
                  ? formData.original_link
                  : formData.new_link
              }
              action={() =>
                onChangeInputText({ target: { value: "" } }, "new_link")
              }
              onChangeHandler={(e: any) => onChangeInputText(e, "new_link")}
            />
          </Col>
        </Row>
        <Row>
          <Col className="offset-1">
            <InputTextarea
              label={
                formData.description === null
                  ? `${t(
                      "views.documents.list.modals.update.fileDescriprionInputLabel"
                    )}`
                  : `${t(
                      "views.documents.list.modals.update.fileDescriprionInputLabel"
                    )} (${t(
                      "views.documents.list.modals.update.fileDescriptionInputCounter"
                    )} ${MAX_DESCRIPTION_LENGTH - formData.description.length})`
              }
              placeholder={t(
                "views.documents.list.modals.update.fileDescriprionInputPlaceholder"
              )}
              value={formData.description}
              rows={10}
              onChangeHandler={(e: any) =>
                onChangeInputText(e, "new_description")
              }
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "2rem" }}>
          <Col className="text-end">
            <Button
              text={t("views.documents.list.modals.update.backButtonText")}
              onClickHandler={onCloseModal}
              variant="secondary"
              outline={true}
              style={{ width: "8rem", marginRight: "1rem" }}
              disabled={isPendingRequest}
            />
            <Button
              text={t("views.documents.list.modals.update.confirmButtonText")}
              type="submit"
              style={{ width: "8rem" }}
              disabled={submitDisabled}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalDocumentsDescriptionComponent;
