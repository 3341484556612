/**
 * @descr Omit a specific field from an object passed as an argument
 * @param key field to omit
 * @param obj target object
 * @returns object without the field identify
 */
export function omit(key: string, obj: any) {
  const { [key]: omitted, ...rest } = obj;
  return rest;
}
