import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState from "./documents.default-state";
import { TableData } from "../../../data-structures/DocumentType";
import { NamespacesData } from "../../../data-structures/NamespaceType";

// CHAT STORE_SLICE
const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    setIsPendingRequest: (state, action) => {
      return {
        ...state,
        isPendingRequest: action.payload,
        requestError: null,
        requestResponse: null,
      };
    },
    setRequestError: (state, action) => {
      return {
        ...state,
        requestError: action.payload,
        isPendingRequest: false,
      };
    },
    setRequestResponse: (state, action) => {
      return {
        ...state,
        requestResponse: action.payload,
        requestError: null,
        isPendingRequest: false,
      };
    },
    setShowModalDocumentsConflict: (state, action) => {
      return {
        ...state,
        showModalDocumentsConflict: action.payload,
      };
    },
    setShowModalDocumentIngestion: (state, action) => {
      return {
        ...state,
        showModalDocumentIngestion: action.payload,
      };
    },
    setShowModalDocumentDescription: (state, action) => {
      return {
        ...state,
        showModalDocumentDescription: action.payload,
      };
    },
    setShowModalDocumentDeletion: (state, action) => {
      return {
        ...state,
        showModalDocumentDeletion: action.payload,
      };
    },
    setShowFilterSidebar: (state, action) => {
      return {
        ...state,
        showFilterSidebar: action.payload,
      };
    },
    setShowModalUnregisteredUser: (state, action) => {
      return {
        ...state,
        showModalUnregisteredUser: action.payload,
      };
    },
    setDocumentsDataConflicts: (state, action) => {
      return {
        ...state,
        documentsDataConflicts:
          action.payload.length === undefined
            ? state.documentsDataConflicts.concat(action.payload)
            : [],
      };
    },
    setChatsData: (state, action) => {
      return {
        ...state,
        chatsData: action.payload,
      };
    },
    setNamespacesData: (state, action: PayloadAction<NamespacesData>) => {
      return {
        ...state,
        namespacesData: action.payload,
      };
    },
    setDocumentsData: (state, action: PayloadAction<TableData[]>) => {
      return {
        ...state,
        documentsData: action.payload,
      };
    },
    removeDocuments: (state, action: PayloadAction<number[]>) => {
      return {
        ...state,
        documentsData: state.documentsData.filter(
          (document) => !action.payload.includes(document._id)
        ),
        selectedDocuments: state.selectedDocuments.filter(
          (document) => !action.payload.includes(document._id)
        ),
      };
    },
    setUsersData: (state, action) => {
      return {
        ...state,
        usersData: action.payload,
      };
    },
    setSelectedDocuments: (state, action) => {
      return {
        ...state,
        selectedDocuments: action.payload,
      };
    },
    setUploadedDocuments: (state, action) => {
      return {
        ...state,
        uploadedDocuments: action.payload,
      };
    },
    setActiveDocument: (state, action) => {
      return {
        ...state,
        activeDocument: action.payload,
      };
    },
    setFilters: (state, action) => {
      return {
        ...state,
        filters: action.payload,
      };
    },
    setUserToken: (state, action) => {
      return {
        ...state,
        userToken: action.payload,
      };
    },
    setUserInfo: (state, action) => {
      return {
        ...state,
        userInfo: action.payload,
      };
    },
  },
});

// Exports actions
export const {
  setIsPendingRequest,
  setRequestError,
  setRequestResponse,
  setShowModalDocumentsConflict,
  setShowModalDocumentIngestion,
  setShowModalDocumentDescription,
  setShowModalDocumentDeletion,
  setShowModalUnregisteredUser,
  setShowFilterSidebar,
  setDocumentsDataConflicts,
  setChatsData,
  setNamespacesData,
  setDocumentsData,
  setUsersData,
  setSelectedDocuments,
  setUploadedDocuments,
  setActiveDocument,
  setFilters,
  setUserToken,
  setUserInfo,
  removeDocuments,
} = documentSlice.actions;

export default documentSlice.reducer;
