import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Iconify from "../../../../components/iconify/iconify";
import { Grid, Stack } from "@mui/material";
import EnhancedTableToolbar from "../../../../components/table/CustomTableToolbar";
import EnhancedTableHead from "../../../../components/table/CustomTableHead";
import { Button } from "../../../../core/components/layout";
import { FaRegTrashCan, FaRegUser } from "react-icons/fa6";
import { RxPencil2 } from "react-icons/rx";
import { t } from "i18next";
import dayjs from "dayjs";
import { Data, NamespaceHeadCell } from "./NamespaceType";
import { Order } from "../../../../components/table/TableOrder";

const headCells: readonly NamespaceHeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "views.namespace.list.table.namespace",
    width: "30%",
  },
  {
    id: "language",
    numeric: false,
    disablePadding: false,
    label: "views.namespace.list.table.language",
    width: "30%",
  },
  {
    id: "creation_date",
    numeric: false,
    disablePadding: false,
    label: "views.namespace.list.table.date",
    width: "30%",
  },
  {
    id: "_id",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "20%",
  },
];

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (orderBy === "name") {
    if (
      (b[orderBy] as string).toLowerCase() <
      (a[orderBy] as string).toLowerCase()
    ) {
      return -1;
    }
    if (
      (b[orderBy] as string).toLowerCase() >
      (a[orderBy] as string).toLowerCase()
    ) {
      return 1;
    }
    return 0;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type Props = {
  data: Data[];
  batchDelete: (selected: Data[]) => void;
  onClickDeleteNamespace: (e: any, row: Data) => void;
  onClickEditNamespaceRoles: (e: any, row: Data) => void;
  onClickEditNamespace: (e: any, row: Data) => void;
};

export default function NamespaceTable({
  data,
  batchDelete,
  onClickDeleteNamespace,
  onClickEditNamespaceRoles,
  onClickEditNamespace,
}: Props) {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("creation_date");
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  React.useEffect(() => {
    setOrder("desc");
    setOrderBy("creation_date");
    setSelected([]);
    setPage(0);
  }, [data]);

  const dense = false;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => {
    if (event.shiftKey) {
      let startIndex = visibleRows.indexOf(
        visibleRows.find((row) => row.id === selected[selected.length - 1])!
      );
      let endIndex = visibleRows.indexOf(
        visibleRows.find((row) => row.id === id)!
      );
      let range = Array.from(
        { length: endIndex - startIndex },
        (_, i) => startIndex + 1 + i
      );
      range.map((index) =>
        setSelected((current) => [...current, visibleRows[index].id])
      );
    } else {
      const selectedIndex = selected.indexOf(id);
      let newSelected: readonly number[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(data, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, data]
  );

  return (
    <>
      <EnhancedTableToolbar
        numTotal={data.length}
        numSelected={selected.length}
        handleBatchDelete={() =>
          batchDelete(data.filter((item) => selected.includes(item.id)))
        }
      />

      <Box
        sx={{
          width: "100%",
          border: "none",
          overflow: "auto",
          maxHeight: "55vh",
        }}
      >
        <TableContainer sx={{ overflowX: "initial" }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750, tableLayout: "fixed" }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              allChecked={selected.length === data.length}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) => handleClick(event, row.id)}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        sx={{ color: "lightgrey" }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Iconify
                          icon="mingcute:round-fill"
                          color={row.icon}
                          sx={{ width: 24, height: 24 }}
                        />
                        <Typography variant="body2">{row.name}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2">{row.language}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2">
                        {dayjs(row.creation_date).format("YYYY-MM-DD HH:mm:ss")}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Grid container>
                        <Grid item xs={4}>
                          <Button
                            variant="light"
                            onClickHandler={(e: any) =>
                              onClickEditNamespaceRoles(e, row)
                            }
                          >
                            <FaRegUser style={{ color: "rgb(11, 73, 239)" }} />
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            variant="light"
                            onClickHandler={(e: any) =>
                              onClickEditNamespace(e, row)
                            }
                          >
                            <RxPencil2 style={{ color: "rgb(0, 208, 255)" }} />
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            variant="light"
                            onClickHandler={(e: any) =>
                              onClickDeleteNamespace(e, row)
                            }
                          >
                            <FaRegTrashCan
                              style={{ color: "rgb(0, 208, 255)" }}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("views.namespace.list.table.options.pagination")}
          sx={{ "& .MuiTablePagination-toolbar": { alignItems: "baseline" } }}
        />
      </Box>
    </>
  );
}
