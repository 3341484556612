import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { DocumentsContent } from "./content";

import withDocument from "./sources/connectors/documents.connector";
import { getNamespacesData, getUserInfo } from "./sources/services";
import { getUsersData } from "../users/sources/services";
import { store } from "../../store/app.store";
import {
  setShowModalDocumentIngestion,
  setUserInfo,
} from "./sources/documents.reducer";
import WrapWithSidebar from "../../components/WrapWithSidebar";
import { useAppDispatch } from "../../store/app.hooks";

const DocumentsContainer: React.FC = (props: any) => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  /**
   * useEffect callback used to show add new document modal
   **/

  useEffect(() => {
    if (searchParams.get("add") !== null) {
      dispatch(setShowModalDocumentIngestion(true));
    }
  }, [searchParams]);

  /**
   * useEffect callback used to get documents data
   **/
  useEffect(() => {
    (async () => {
      let namespaceList = await getNamespacesData({ role: "user" });
      let show_modal = searchParams.get("add") !== null ? true : false;
      let user = await getUserInfo();
      getUsersData().then((data: any) => {
        if (data.code === 403) {
          props.requestGetDocumentsData(show_modal);
        } else {
          let userList = data;
          let currentUser = userList.filter(
            (u: any) => u.email === user.email
          )[0];
          if (currentUser.is_admin) {
            let user_data = { role: "admin", email: currentUser.email };
            store.dispatch(setUserInfo(user_data));
          }
          if (namespaceList.namespaces.length !== 0) {
            props.requestGetDocumentsData(show_modal);
          }
        }
      });
    })();
  }, []);

  return (
    <WrapWithSidebar>
      <DocumentsContent {...props} />
    </WrapWithSidebar>
  );
};

export default withDocument(DocumentsContainer);
