import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Order } from "../../components/table/TableOrder";
import EnhancedTableHead from "../../components/table/CustomTableHead";
import {
  DocumentFilter,
  DocumentWithStatus,
  DocumentWithStatusHeadCell,
  FilterParamsWithStatus,
  OrderParamsWithStatus,
} from "../../data-structures/DocumentType";
import Iconify from "../../components/iconify";
import useDocumentsApi from "../../api/DocumentsApi";
import { setUploadedDocuments } from "../documents/sources/documents.reducer";
import { Namespace } from "../../data-structures/NamespaceType";
import TableLoader from "../../components/table/TableLoader";
import { useAppDispatch } from "../../store/app.hooks";
import { PaginationMetadata } from "../../commons/types";

const headCells: readonly DocumentWithStatusHeadCell[] = [
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "views.documents.list.table.status",
    width: "10%",
    sortable: true,
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "views.documents.list.table.documentName",
    width: "15%",
    sortable: true,
  },
  {
    id: "namespace_name",
    numeric: false,
    disablePadding: false,
    label: "views.documents.list.table.namespace",
    width: "15%",
    sortable: true,
  },
  {
    id: "user_id",
    numeric: false,
    disablePadding: false,
    label: "views.documents.list.sidebar.userInputLabel",
    width: "15%",
    sortable: true,
  },
  {
    id: "start_upload_date",
    numeric: true,
    disablePadding: false,
    label: "views.documents.list.table.uploadedOn",
    width: "15%",
    sortable: true,
  },
  {
    id: "end_upload_date",
    numeric: true,
    disablePadding: false,
    label: "views.documents.list.table.uploadTime",
    width: "10%",
    sortable: false,
  },
];

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type Props = {
  data: DocumentWithStatus[];
  filters: DocumentFilter;
  namespacesData: Namespace[];
};

export default function StatusTable({ data, filters, namespacesData }: Props) {
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [paginationMetaData, setPaginationMetaData] =
    React.useState<PaginationMetadata>({});
  const [tableDataLoading, setTableDataLoading] = React.useState(false);
  const [orderParams, setOrderParams] = React.useState<OrderParamsWithStatus>({
    orderBy: "start_upload_date",
    order: "-",
  });
  const { t } = useTranslation();
  const { getDocumentsStatus } = useDocumentsApi();

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const getDocs = async () => {
      setTableDataLoading(true);
      const paginationParams = {
        page: page + 1,
        page_size: rowsPerPage,
      };
      const filterParams: FilterParamsWithStatus = {};

      if (filters.users) {
        filterParams.user_id = filters.users[0];
      }
      if (filters._id_namespaces) {
        filterParams._id_namespaces = filters._id_namespaces;
      }
      if (filters.from_date) {
        filterParams.from_date = filters.from_date;
      }
      if (filters.to_date) {
        filterParams.to_date = filters.to_date;
      }
      if (filters.title) {
        filterParams.title = filters.title;
      }
      if (filters.state) {
        filterParams.state = filters.state[0];
      }
      const result = await getDocumentsStatus(
        paginationParams,
        filterParams,
        orderParams
      );

      if (result.documents.length === 0) {
        if (!ignore) {
          setPaginationMetaData({});
          dispatch(setUploadedDocuments([]));
          setTableDataLoading(false);
        }
      }

      if (result.documents.length > 0 && namespacesData.length > 0) {
        let init_table_data: any = [];
        result.documents.forEach((item: DocumentWithStatus) => {
          const name =
            namespacesData.find((ns: any) => ns._id === item._id_namespace)
              ?.name || "";

          init_table_data.push({
            ...item,
            namespace_name: name,
          });
        });

        if (!ignore) {
          setPaginationMetaData(result.pagination_metadata);
          dispatch(setUploadedDocuments(init_table_data));
          setTableDataLoading(false);
        }
      }
    };
    let ignore = false;
    getDocs();
    return () => {
      ignore = true;
    };
  }, [filters, page, rowsPerPage, namespacesData, orderParams]);

  React.useEffect(() => {
    setPage(0);
  }, [filters]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DocumentWithStatus
  ) => {
    if (property === "end_upload_date") return;
    const isAsc = orderParams.orderBy === property && orderParams.order === "+";
    setOrderParams({ orderBy: property, order: isAsc ? "-" : "+" });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    paginationMetaData.last_page &&
    paginationMetaData.total_pages &&
    paginationMetaData.total_pages > 1
      ? Math.max(0, Math.min(8, rowsPerPage) - data.length)
      : 0;

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          border: "none",
          overflow: "auto",
          maxHeight: "450px",
        }}
      >
        <TableContainer
          sx={{
            overflowX: "initial",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Table
            stickyHeader
            sx={{
              minWidth: 750,
              tableLayout: "fixed",
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              selectable={false}
              order={orderParams.order === "-" ? "desc" : "asc"}
              orderBy={orderParams.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              headCells={headCells}
            />
            <TableBody>
              {!tableDataLoading &&
                data.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell
                        title={t(`views.documents.status.${row.state}`)}
                        align="left"
                      >
                        <Stack direction={"row"} spacing={1}>
                          {row.state === "DONE" && (
                            <Iconify
                              icon={"material-symbols:done"}
                              sx={{ color: "green" }}
                            />
                          )}
                          {row.state === "ERROR" && (
                            <Iconify
                              icon={"material-symbols-light:error"}
                              sx={{ color: "red" }}
                            />
                          )}
                          {row.state === "PROCESSING" && (
                            <Iconify
                              icon={"eos-icons:loading"}
                              sx={{ color: "yellow" }}
                            />
                          )}
                          <Typography
                            variant="body2"
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {t(`views.documents.status.${row.state}`)}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        title={row.title}
                        align="left"
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row.title}
                        </Typography>
                      </TableCell>
                      <TableCell title={row.namespace_name} align="left">
                        <Typography
                          variant="body2"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row.namespace_name}
                        </Typography>
                      </TableCell>
                      <TableCell title={row.user_id} align="left">
                        <Typography
                          variant="body2"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row.user_id}
                        </Typography>
                      </TableCell>
                      <TableCell
                        title={dayjs(row.start_upload_date).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                        align="left"
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {dayjs(row.start_upload_date).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        title={
                          row.end_upload_date === null
                            ? ""
                            : `${dayjs(row.end_upload_date).diff(
                                dayjs(row.start_upload_date),
                                "seconds"
                              )} s`
                        }
                        align="left"
                      >
                        {row.end_upload_date === null ? (
                          <Iconify icon={"eos-icons:loading"} />
                        ) : (
                          <Typography
                            variant="body2"
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {dayjs(row.end_upload_date).diff(
                              dayjs(row.start_upload_date),
                              "seconds"
                            )}{" "}
                            s
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && !tableDataLoading && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TableLoader
            show={tableDataLoading}
            height={rowsPerPage === 5 ? 265 : 393}
          />
        </TableContainer>
      </Box>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={paginationMetaData.total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("views.namespace.list.table.options.pagination")}
        sx={{
          "& .MuiTablePagination-toolbar": { alignItems: "baseline" },
        }}
      />
    </Box>
  );
}
