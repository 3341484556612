import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import {
  ModalDocumentsDelete,
  ModalDocumentsDescription,
  ModalDocumentsIngestion,
  SidebarFilter,
  TableDocumentsComponent,
} from "./components";
import {
  IsPendingRequest,
  RequestError,
  RequestResponse,
  ShowModalDocumentIngestion,
  ShowModalDocumentDescription,
  ShowModalDocumentDeletion,
  ShowFilterSidebar,
  DocumentsData,
  SelectedDocuments,
  ActiveDocument,
  Filters,
  UsersData,
  DocumentsDataConflicts,
  OnSetShowModalDocumentIngestion,
  OnSetShowModalDocumentDescription,
  OnSetShowModalDocumentDeletion,
  OnSetSelectedDocuments,
  OnSetShowFilterSidebar,
  OnSetFilters,
  OnUploadDocuments,
  OnDeleteDocuments,
  OnUpdateDocument,
  OnDownloadDocument,
  ShowModalDocumentsConflict,
  OnGetDocumentsData,
  OnSetDocumentsDataConflicts,
  UserInfo,
  OnSetShowModalUnregisteredUser,
} from "../types";
import useNamespaceApi from "../../../api/NamespaceApi";
import { useSearchParams } from "react-router-dom";
import { HcError } from "../../../api/ApiErrors";
import { Toast } from "../../../core/components/layout";
import { useAppDispatch } from "../../../store/app.hooks";
import {
  setRequestError,
  setRequestResponse,
} from "../sources/documents.reducer";
import CustomModal from "../../../commons/CustomModal";

interface DocumentsProps {
  isPendingRequest: IsPendingRequest;
  requestError: RequestError;
  requestResponse: RequestResponse;
  showModalDocumentsConflict: ShowModalDocumentsConflict;
  showModalDocumentIngestion: ShowModalDocumentIngestion;
  showModalDocumentDescription: ShowModalDocumentDescription;
  showModalDocumentDeletion: ShowModalDocumentDeletion;
  showFilterSidebar: ShowFilterSidebar;
  documentsDataConflicts: DocumentsDataConflicts;
  documentsData: DocumentsData;
  usersData: UsersData;
  selectedDocuments: SelectedDocuments;
  activeDocument: ActiveDocument;
  filters: Filters;
  userInfo: UserInfo;
  requestGetDocumentsData: OnGetDocumentsData;
  requestUploadDocuments: OnUploadDocuments;
  requestSetShowModalDocumentIngestion: OnSetShowModalDocumentIngestion;
  requestSetShowModalDocumentDescription: OnSetShowModalDocumentDescription;
  requestSetShowModalDocumentDeletion: OnSetShowModalDocumentDeletion;
  requestSetShowModalUnregisteredUser: OnSetShowModalUnregisteredUser;
  requestSetSelectedDocuments: OnSetSelectedDocuments;
  requestSetShowFilterSidebar: OnSetShowFilterSidebar;
  requestDownloadDocument: OnDownloadDocument;
  requestUpdateDocument: OnUpdateDocument;
  requestDeleteDocuments: OnDeleteDocuments;
  requestSetFilters: OnSetFilters;
  requestSetDocumentsDataConflicts: OnSetDocumentsDataConflicts;
}

const DocumentsContent: React.FC<DocumentsProps> = ({
  isPendingRequest,
  showModalDocumentIngestion,
  showModalDocumentDescription,
  showModalDocumentDeletion,
  showFilterSidebar,
  documentsData,
  documentsDataConflicts,
  selectedDocuments,
  activeDocument,
  filters,
  requestUploadDocuments,
  requestSetShowModalDocumentIngestion,
  requestSetShowModalDocumentDescription,
  requestSetShowModalDocumentDeletion,
  requestSetShowModalUnregisteredUser,
  requestSetSelectedDocuments,
  requestSetShowFilterSidebar,
  requestDeleteDocuments,
  requestDownloadDocument,
  requestUpdateDocument,
  requestSetFilters,
  requestSetDocumentsDataConflicts,
  requestResponse,
  requestError,
}) => {
  const [namespacesData, setNamespacesData] = useState<any[]>([]);
  const { getNamespaceListByRole, getNamespaces } = useNamespaceApi();
  const [searchParams] = useSearchParams();
  const [showInsufficientGrantsModal, setShowInsufficientGrantsModal] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("add") !== null)
      requestSetShowModalDocumentIngestion(true);
    getNamespaceListByRole("supervisor").then((namespaceList) => {
      if (namespaceList instanceof HcError) {
        if (namespaceList.response.code === 401)
          requestSetShowModalUnregisteredUser(true);
        return false;
      }
      if (namespaceList.length === 0) {
        setShowInsufficientGrantsModal(true);
        return;
      }
      getNamespaces(namespaceList).then((namespaces) => {
        setNamespacesData(namespaces);
      });
    });
  }, []);

  /**
   * useEffect callback used to display a Toast with success or error message
   **/
  useEffect(() => {
    if (requestResponse) {
      const code = requestResponse.code;
      const type = requestResponse.type;
      Toast("success", t(`errors.documents.${type}.${code}`));
      dispatch(setRequestResponse(null));
    }
    if (requestError) {
      const code = requestError.code;
      const type = requestError.type;
      Toast("error", t(`errors.documents.${type}.${code}`));
      dispatch(setRequestError(null));
    }
  }, [requestResponse, requestError]);

  const handleCloseNonSupervisorModal = () => {
    setShowInsufficientGrantsModal(false);
    navigate("/chat");
  };

  return (
    <Container
      fluid={true}
      className="document-content"
      style={{ height: "100vh" }}
    >
      <Row className="justify-content-md-center" style={{ height: "100%" }}>
        <Col
          lg={10}
          md={10}
          style={{ paddingTop: "2rem", height: "100%", paddingBottom: "2rem" }}
        >
          <TableDocumentsComponent
            isPendingRequest={isPendingRequest && namespacesData.length === 0}
            filters={filters}
            selectedDocuments={selectedDocuments}
            namespacesData={namespacesData}
            onSetFilters={requestSetFilters}
            onSetSelectedDocuments={requestSetSelectedDocuments}
            onSetShowModalDocumentDescription={
              requestSetShowModalDocumentDescription
            }
            onSetShowModalDocumentIngestion={
              requestSetShowModalDocumentIngestion
            }
            onSetShowModalDocumentDeletion={requestSetShowModalDocumentDeletion}
            onSetShowFilterSidebar={requestSetShowFilterSidebar}
            onDownloadDocument={requestDownloadDocument}
          />
        </Col>
      </Row>

      {/* Advanced filters */}
      <SidebarFilter
        showFilterSidebar={showFilterSidebar}
        documentsData={documentsData}
        namespacesData={namespacesData}
        filters={filters}
        onSetFilters={requestSetFilters}
        onSetShowFilterSidebar={requestSetShowFilterSidebar}
      />

      {/* Modal add new document */}
      <ModalDocumentsIngestion
        isPendingRequest={isPendingRequest}
        showModalDocumentIngestion={showModalDocumentIngestion}
        namespacesData={namespacesData}
        documentsDataConflicts={documentsDataConflicts}
        onUploadDocuments={requestUploadDocuments}
        onSetShowModalDocumentIngestion={requestSetShowModalDocumentIngestion}
        onSetDocumentsDataConflicts={requestSetDocumentsDataConflicts}
      />

      {/* Modal document description */}
      <ModalDocumentsDescription
        isPendingRequest={isPendingRequest}
        showModalDocumentDescription={showModalDocumentDescription}
        documentsDataConflicts={documentsDataConflicts}
        activeDocument={activeDocument}
        onUpdateDocument={requestUpdateDocument}
        onSetShowModalDocumentDescription={
          requestSetShowModalDocumentDescription
        }
      />

      {/* Modal delete document */}
      <ModalDocumentsDelete
        isPendingRequest={isPendingRequest}
        showModalDocumentDeletion={showModalDocumentDeletion}
        selectedDocuments={selectedDocuments}
        onDeleteDocuments={requestDeleteDocuments}
        onSetShowModalDocumentDeletion={requestSetShowModalDocumentDeletion}
      />
      <CustomModal
        singleButton={true}
        textList={["modal.documents.revocedGrants"]}
        showModal={showInsufficientGrantsModal}
        button1Text="modal.actions.navToHome"
        onConfirm={handleCloseNonSupervisorModal}
      />
    </Container>
  );
};

export default DocumentsContent;
