import React, { useEffect } from "react";
import UsersContent from "./content/UsersContent";
import withUsers from "./sources/connectors/users.connectors";
import {
  getNamespacesData,
  getUserInfo,
  getUsersData,
} from "./sources/services";
import { store } from "../../store/app.store";
import {
  setIsPendingRequest,
  setUserInfo,
  setUsersData,
} from "./sources/users.reducer";
import WrapWithSidebar from "../../components/WrapWithSidebar";

const UsersContainer: React.FC = (props: any) => {
  /**
   * function used to retrieve and populate the global users view statement
   */
  useEffect(() => {
    (async () => {
      let namespaceList = await getNamespacesData({ role: "user" });
      let user = await getUserInfo();
      getUsersData().then((data: any) => {
        if (data.code === 403) {
          props.requestGetUsersData();
        } else {
          let userList = data;
          let currentUser = userList.filter(
            (u: any) => u.email === user.email
          )[0];
          if (currentUser.is_admin) {
            console.log("ADMIN");
            let user_data = { role: "admin", email: currentUser.email };
            store.dispatch(setUserInfo(user_data));
          }
          if (namespaceList.namespaces.length === 0) {
            let users_data_response: any = data;
            store.dispatch(setUsersData(users_data_response));
            store.dispatch(setIsPendingRequest(false));
          } else {
            props.requestGetUsersData();
          }
        }
      });
    })();
  }, []);

  return (
    <WrapWithSidebar>
      <UsersContent {...props} />
    </WrapWithSidebar>
  );
};

export default withUsers(UsersContainer);
