import { env } from "./env";

const REACT_APP_CLIENT_ID: string = env.REACT_APP_CLIENT_ID as string;
const REACT_APP_CODE_VERIFIER: string = env.REACT_APP_CODE_VERIFIER as string;
const REACT_APP_REDIRECT_URL: string = env.REACT_APP_REDIRECT_URL as string;
const REACT_APP_REST_API_URL: string = env.REACT_APP_REST_API_URL as string;

export {
  REACT_APP_CLIENT_ID,
  REACT_APP_CODE_VERIFIER,
  REACT_APP_REDIRECT_URL,
  REACT_APP_REST_API_URL,
};

export const REST_API_URL: string = REACT_APP_REST_API_URL
  ? REACT_APP_REST_API_URL
  : `${window.location.origin}`;
