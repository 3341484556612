import {
  DocumentWithStatus,
  TableData,
} from "../../../data-structures/DocumentType";
import { NamespacesData } from "../../../data-structures/NamespaceType";

type DocumtentState = {
  isPendingRequest: boolean;
  requestError: any;
  requestResponse: any;
  showModalDocumentsConflict: boolean;
  showModalDocumentIngestion: boolean;
  showModalDocumentDescription: boolean;
  showModalDocumentDeletion: boolean;
  showModalUnregisteredUser: boolean;
  showFilterSidebar: boolean;
  userToken: any;
  userInfo: any;
  chatsData: any[];
  usersData: any[];
  documentsData: TableData[];
  documentsDataConflicts: any[];
  namespacesData: NamespacesData;
  selectedDocuments: TableData[];
  uploadedDocuments: DocumentWithStatus[];
  activeDocument: any;
  filters: any;
};

const initialState: DocumtentState = {
  isPendingRequest: false,
  requestError: null,
  requestResponse: null,
  showModalDocumentsConflict: false,
  showModalDocumentIngestion: false,
  showModalDocumentDescription: false,
  showModalDocumentDeletion: false,
  showModalUnregisteredUser: false,
  showFilterSidebar: false,
  userToken: null,
  userInfo: null,
  chatsData: [],
  usersData: [],
  documentsData: [],
  documentsDataConflicts: [],
  namespacesData: [],
  selectedDocuments: [],
  uploadedDocuments: [],
  activeDocument: null,
  filters: {},
};

export default initialState;
