import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Button, Modal } from "../../../../core/components/layout";
import InputSearch from "../../../../core/components/form/InputSearch/InputSearch";
import InputTextComponent from "../../../../core/components/form/InputText/InputText";
import Scrollbar from "react-scrollbars-custom";
// import { useNavigate } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
import {
  ActiveNamespace,
  NamespaceConflictObject,
  NamespacesData,
  NamespacesIcon,
  OnSetShowModalNamespaceMetadata,
  OnUpdateNamespaceMetadata,
  ShowModalNamespaceMetadata,
} from "../../types";
import { BiWorld } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import languages from "../../../../assets/languages/languages.json";
import styles from "../../../../core/style/global/global.module.scss";
import { trimStr } from "../../../../core/services";
import { NAMESPACE_ICONS } from "./NamespaceColorIcons";

const DEFAULT_FORM = {
  name: "",
  new_name: "",
  icon: null,
  new_icon: null,
  language: [],
  conflict: false,
};

interface ModalNamespaceProps {
  showModalNamespaceMetadata: ShowModalNamespaceMetadata;
  activeNamespace: ActiveNamespace;
  namespacesIcon: NamespacesIcon;
  namespacesData: NamespacesData;
  namespaceConflictObject: NamespaceConflictObject;
  onUpdateNamespaceMetadata: OnUpdateNamespaceMetadata;
  onSetShowModalNamespaceMetadata: OnSetShowModalNamespaceMetadata;
}

const ModalNamespaceMetadataComponent: React.FC<ModalNamespaceProps> = ({
  showModalNamespaceMetadata,
  activeNamespace,
  namespacesData,
  namespaceConflictObject,
  onUpdateNamespaceMetadata,
  onSetShowModalNamespaceMetadata,
}) => {
  const LANGUAGES_OPT = Object.keys(languages);
  const { t } = useTranslation();
  const form_ref: any = useRef(null);
  const [mouseHover, setMouseHover] = useState<any>({
    hover: false,
    index: -1,
  }); // remove with scss
  const [selectedIcon, setSelectedIcon] = useState<any>({ icon: null }); // remove with scss
  const [slicedNamespacesData, setSlicedNamespacesData] = useState<any>([]);
  const [languageOpt, setLanguageOpt] = useState<any>([]);
  const [formData, setFormData] = useState<any>(DEFAULT_FORM);
  // const history = useNavigate();

  /**
   * @descr Function that split an array in to a list of arrays of lenght "size"
   * @param {Array<any>} arr Array to split
   * @param {number} size size of each sub-array
   * @returns An array of "size" length arrays.
   */
  const chunk = (arr: any, size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );

  const getLanguageCode = (language: any) => {};

  /**
   * @descr Update the form field value with the retrieved inputs data,
   *        than check (and reset if needed) the conflict value if the input namespace name length is empty.
   * @param {string} field form field to update
   * @param {any} data input data retrieved
   */
  const onChangeFormDataHandler = (field: string, data: any) => {
    let form_data = {
      ...formData,
      [field]: data,
      conflict:
        formData.conflict && formData.name === formData.new_name ? true : false,
    };

    setFormData(form_data);
  };

  /**
   * @descr function used as callback to update the namespace icon value inside the form data
   * @param {Array<string>} ns_icon namespace icon value (html color code)
   */
  const onClickSelectNamespaceHandler = (ns_icon: any) => {
    setSelectedIcon({ icon: ns_icon });
    let form_data = {
      ...formData,
      new_icon: [ns_icon],
    };

    setFormData(form_data);
  };

  /**
   * @descr function used as callback to invoke the effect that send an update metadata request to the service. Than reset the form data.
   * @param {Object} e click event trigger
   */
  const onClickConfirm = (e: any) => {
    e.preventDefault();

    let form_data = {
      ...formData,
      new_name: trimStr(formData.new_name),
    };

    onUpdateNamespaceMetadata(form_data, namespacesData);
    setFormData(DEFAULT_FORM);
    setMouseHover({ hover: false, index: -1 });
    setSelectedIcon({ icon: null });
    if (form_ref.current !== null) form_ref.current.reset();
  };

  // FUTURE FEATURE ON CUSTOM IMG NS_ICON
  // const onDropFile = (acceptedFiles:any) => {
  //     let file_successfully_uploaded:any = [];

  //     acceptedFiles.forEach((file:any) => {
  //         const reader = new FileReader();
  //         reader.onabort = () => { console.log( `File reading was aborted`); }
  //         reader.onerror = () => { console.log(`File reading was failed`); }
  //         reader.onload = (e:any) => {
  //             const binary_file:any = reader.result;
  //             const base64 = binary_file.slice(binary_file.indexOf(',')+1);
  //             let file_data:any = {id: uuidv4(), name: file.name, file: base64, conflict: false};
  //             file_successfully_uploaded.push(file_data);
  //             setFilesUploaded(file_successfully_uploaded);
  //         }
  //         reader.readAsDataURL(file);
  //     });
  // }

  // Set the global state for showModal to false
  const onCloseModal = () => {
    setFormData(DEFAULT_FORM);
    setMouseHover({ hover: false, index: -1 }); // remove with scss
    setSelectedIcon({ icon: null }); // remove with scss
    onSetShowModalNamespaceMetadata(!showModalNamespaceMetadata);
    if (form_ref.current !== null) form_ref.current.reset();
  };

  useEffect(() => {
    if (form_ref.current !== null) form_ref.current.reset();
    if (activeNamespace !== null) {
      let existing_icon = NAMESPACE_ICONS.map((ns: any, index: any) => {
        if (ns.icon === activeNamespace.icon) return ns.icon;
      }).filter((item: any) => item !== undefined)[0];
      // pre-set the active namespace icon
      setSelectedIcon({ icon: existing_icon });

      let form_data = {
        ...formData,
        id: activeNamespace._id,
        name: activeNamespace.name,
        icon: activeNamespace.icon,
        language: [activeNamespace.language],
        conflict: false,
      };

      setFormData(form_data);
    }
  }, [activeNamespace]);

  // Split the namespaces 'icons' data in group by 4 elements.
  useEffect(() => {
    if (form_ref.current !== null) form_ref.current.reset();
    setSlicedNamespacesData(chunk(NAMESPACE_ICONS, 4));
  }, []);

  // Set and notify to the user if any conflict was detected when entering the chosen name
  useEffect(() => {
    if (form_ref.current !== null) form_ref.current.reset();
    if (namespaceConflictObject !== null) setFormData(namespaceConflictObject);
  }, [namespaceConflictObject]);

  // Set the language options
  useEffect(() => {
    if (form_ref.current !== null) form_ref.current.reset();
    let languages_opt_translated = LANGUAGES_OPT.map((language: any) =>
      t(`views.namespace.list.sidebar.languageSelection.${language}`)
    );
    setLanguageOpt(languages_opt_translated);
  }, []);

  const nameValue =
    formData.new_name.length === 0 ? formData.name : formData.new_name;

  return (
    <Modal
      visible={showModalNamespaceMetadata}
      size="lg"
      onHideHandler={onCloseModal}
      title={activeNamespace !== null ? <h5>{activeNamespace.name}</h5> : ""}
    >
      {activeNamespace !== null && (
        <Form ref={form_ref}>
          {/* Namespace input name */}
          <Row style={{ margin: "15px 15px" }}>
            <InputTextComponent
              label={t("views.namespace.list.modals.update.inputLabel")}
              placeholder={t(
                "views.namespace.list.modals.update.inputPlaceholder"
              )}
              value={nameValue}
              style={{ color: formData.conflict ? styles.danger : styles.dark }}
              onChangeHandler={(e: any) =>
                onChangeFormDataHandler("new_name", e.target.value)
              }
            />
          </Row>

          {/* Namespace icon grid form */}
          <Scrollbar style={{ height: "30vh" }}>
            {slicedNamespacesData.map((row_items: any, row_index: any) => {
              return (
                <Row style={{ margin: "15px 15px" }} key={10 * (row_index + 1)}>
                  {row_items.map((item: any, col_index: any) => {
                    let consecutive_item_index =
                      slicedNamespacesData[row_index].length * row_index +
                      col_index;
                    return (
                      <Col
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        style={{ display: "grid" }}
                        key={100 * (row_index + 1) + col_index}
                      >
                        <div
                          // className="namespace-item" // SCSS per la gestione del onHover da scommentare dopo la pull
                          key={consecutive_item_index}
                          style={{
                            height: "4rem",
                            width: "4rem",
                            placeSelf: "center",
                            margin: "20px 0",
                            backgroundColor: item.icon,
                            borderRadius: 90,
                            borderColor: "#FFFFFF",
                            borderStyle:
                              (mouseHover.hover &&
                                mouseHover.index === consecutive_item_index) ||
                              selectedIcon.icon === item.icon
                                ? "solid"
                                : "",
                            transform:
                              (mouseHover.hover &&
                                mouseHover.index === consecutive_item_index) ||
                              selectedIcon.icon === item.icon
                                ? "scale(1.2)"
                                : "",
                            boxShadow:
                              (mouseHover.hover &&
                                mouseHover.index === consecutive_item_index) ||
                              selectedIcon.icon === item.icon
                                ? "0px 0px 15px #4195fc"
                                : "",
                          }}
                          onMouseEnter={() =>
                            setMouseHover({
                              hover: true,
                              index: consecutive_item_index,
                            })
                          }
                          onMouseLeave={() =>
                            setMouseHover({ hover: false, index: -1 })
                          }
                          onClick={() =>
                            onClickSelectNamespaceHandler(item.icon)
                          }
                        />
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </Scrollbar>
          <Row style={{ margin: "30px 0 15px 15px" }}>
            <Col xs={4} sm={4} md={4} lg={4}>
              <BiWorld style={{ marginRight: ".3rem", fontSize: 16 }} />
              <InputSearch
                label={t("views.namespace.list.modals.update.languageLabel")}
                placeholder={t(
                  "views.namespace.list.modals.update.languagePlaceholder"
                )}
                options={languageOpt}
                disabled={activeNamespace !== null}
                selected={
                  activeNamespace !== null
                    ? [
                        t(
                          `views.namespace.list.modals.update.languageSelection.${activeNamespace.language}`
                        ),
                      ]
                    : []
                }
                style={{ fontSize: 14 }}
                onChangeHandler={(item: any) =>
                  onChangeFormDataHandler("language", item)
                }
              />
            </Col>
          </Row>
          {/* Footer buttons */}
          <Row>
            <Col className="text-end">
              <Button
                text={t("views.namespace.list.modals.update.confirmButtonText")}
                className={"modal-upload-button"}
                variant={
                  selectedIcon.icon === null || formData.name.length === 0
                    ? "secondary"
                    : "primary"
                }
                style={{ width: "15%" }}
                disabled={
                  selectedIcon.icon === null || formData.name.length === 0
                }
                onClickHandler={onClickConfirm}
              />
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default ModalNamespaceMetadataComponent;
