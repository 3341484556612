import {
  TableHead,
  TableCell,
  Checkbox,
  TableSortLabel,
  Box,
  TableRow,
} from "@mui/material";
import { t } from "i18next";
import { visuallyHidden } from "@mui/utils";
import { Data } from "../../views/namespace/content/components/NamespaceType";
import { Order } from "./TableOrder";

interface EnhancedTableProps {
  selectable?: boolean;
  numSelected?: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: readonly any[];
  checkBoxWidth?: string;
  allChecked?: boolean | undefined;
}

export default function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    selectable = true,
    headCells,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    checkBoxWidth,
    allChecked = undefined,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow sx={{ alignItems: "left" }}>
        {selectable && (
          <TableCell
            padding="checkbox"
            sx={checkBoxWidth ? { width: checkBoxWidth } : {}}
          >
            <Checkbox
              color="primary"
              indeterminate={
                typeof numSelected === "number" &&
                numSelected > 0 &&
                (allChecked === false || numSelected < rowCount)
              }
              checked={
                typeof numSelected === "number" &&
                rowCount > 0 &&
                numSelected >= rowCount &&
                allChecked
              }
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
              sx={{ color: "lightgrey" }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.width,
              minWidth: headCell.width,
              "&>*": { color: "rgb(11, 73, 239)!important" },
            }}
          >
            <TableSortLabel
              hideSortIcon={!headCell.sortable}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ "&>*": { color: "rgb(11, 73, 239)!important" } }}
            >
              {t(headCell.label)}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
