declare global {
  interface Window {
    env: any;
  }
}

type EnvType = {
  REACT_APP_TIMEZONE: string;
  REACT_APP_NAME: string;
  REACT_APP_ID: string;
  REACT_APP_CLIENT_ID: string;
  REACT_APP_CODE_VERIFIER: string;
  REACT_APP_REDIRECT_URL: string;
  REACT_APP_REST_API_URL: string;
};

export const env: EnvType = { ...process.env, ...window.env };
