import { ID } from "../../../../config";
import { REACT_APP_REST_API_URL } from "../../../../custom.config";
import { getCookie } from "../../../../core/services";
import type {
  FilterParams,
  OrderParams,
  PaginationParams,
} from "../../../../data-structures/DocumentType";
import { Toast } from "../../../../core/components/layout";
import { t } from "i18next";
//Check if rest api url is defined as env variable
const REST_API_URL: string = REACT_APP_REST_API_URL
  ? REACT_APP_REST_API_URL
  : `${window.location.origin}`;

const ERROR_STATUS: any = [401, 402, 403, 404, 500];

const handleError = (error_data: any, type: string) => {
  let error: any = new Error();
  error.name = "HyperChat Error";
  error.response = {
    ...error_data,
    type: type,
  };

  return error;
};

/**
 * Get chats data
 * @returns {Promise<*>} user chats data
 */
export async function getChats() {
  try {
    const response = await fetch(
      `${REST_API_URL}/api/hyperchat/chat/list_chats`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
      }
    );

    //Check response
    if (response.ok) {
      return await response.json();
    } else {
      if (ERROR_STATUS.includes(response.status)) {
        let res_err: any = {
          code: response.status,
          status: `${response.status} error`,
        };
        throw handleError(res_err, "retrieve");
      } else {
        let res_err: any = await response.json();
        throw handleError(res_err, "retrieve");
      }
    }
  } catch (error: any) {
    return error.response;
  }
}

/**
 * Get documents data
 * @returns {Promise<*>} retrieve all documents
 */
export async function getDocumentsData() {
  try {
    const response = await fetch(
      `${REST_API_URL}/api/hyperchat/handle_documents/retrieve_documents?page_size=5&_sort_by=-upload_date`,
      {
        headers: {
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
      }
    );

    // Check response
    if (response.ok) {
      return await response.json();
    } else {
      if (ERROR_STATUS.includes(response.status)) {
        let res_err: any = {
          code: response.status,
          status: `${response.status} error`,
        };
        throw handleError(res_err, "retrieve");
      } else {
        let res_err: any = await response.json();
        throw handleError(res_err, "retrieve");
      }
    }
  } catch (error: any) {
    return error.response;
  }
}

/**
 * @descr Check if insiede the namespace passed as an argument to the function there is at least one document with the same file name.
 * @param document_title title of the document
 * @param namespaces_id namespace id
 */
export async function checkDocumentTitle(
  document_title: any,
  namespaces_id: any
) {
  try {
    const response = await fetch(
      `${REST_API_URL}/api/hyperchat/handle_documents/check_document?title=${document_title}&_id_namespaces=${namespaces_id}`,
      {
        headers: {
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
      }
    );

    // Check response
    if (response.ok) {
      return await response.json();
    } else {
      if (ERROR_STATUS.includes(response.status)) {
        let res_err: any = {
          code: response.status,
          status: `${response.status} error`,
        };
        throw handleError(res_err, "check");
      } else {
        let res_err: any = await response.json();
        throw handleError(res_err, "check");
      }
    }
  } catch (error: any) {
    return error.response;
  }
}

/**
 * @descr upload the document on the service
 * @param data document data
 */
export async function uploadDocuments(data: any) {
  try {
    const response = await fetch(
      `${REST_API_URL}/api/hyperchat/handle_documents/upload`,
      {
        headers: {
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
        method: "POST",
        body: data,
      }
    );

    // Check response
    if (response.ok) {
      return await response.json();
    } else {
      if (ERROR_STATUS.includes(response.status)) {
        let res_err: any = {
          code: response.status,
          status: `${response.status} error`,
        };
        throw handleError(res_err, "upload");
      } else {
        let res_err: any = await response.json();
        throw handleError(res_err, "upload");
      }
    }
  } catch (error: any) {
    return error.response;
  }
}

/**
 * @descr update the document metadata
 * @param data document data
 */
export async function updateDocument(data: any) {
  try {
    const response = await fetch(
      `${REST_API_URL}/api/hyperchat/handle_documents/edit_metadata`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
        method: "POST",
        body: JSON.stringify(data),
      }
    );

    // Check response
    if (response.ok) {
      return await response.json();
    } else {
      if (ERROR_STATUS.includes(response.status)) {
        let res_err: any = {
          code: response.status,
          status: `${response.status} error`,
        };
        throw handleError(res_err, "edit");
      } else {
        let res_err: any = await response.json();
        throw handleError(res_err, "edit");
      }
    }
  } catch (error: any) {
    return error.response;
  }
}

/**
 * @descr archive the identify document
 * @param data document data
 */
export async function deleteDocuments(data: any) {
  try {
    const response = await fetch(
      `${REST_API_URL}/api/hyperchat/handle_documents/archive_document`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
        method: "POST",
        body: JSON.stringify(data),
      }
    );

    // Check response
    if (response.ok) {
      return await response.json();
    } else {
      if (ERROR_STATUS.includes(response.status)) {
        let res_err: any = {
          code: response.status,
          status: `${response.status} error`,
        };
        throw handleError(res_err, "delete");
      } else {
        let res_err: any = await response.json();
        throw handleError(res_err, "delete");
      }
    }
  } catch (error: any) {
    return error.response;
  }
}

/**
 * @desc this function performs a refresh of the retrieving session user token
 * @return array of objects
 */
export async function getUserTokenData(data: any) {
  try {
    const response = await fetch(
      `https://login.microsoftonline.com/56ac8205-a37e-4856-a53f-686943febf7b/oauth2/v2.0/token`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        body: data,
      }
    );

    // Check response
    if (response.ok) {
      return await response.json();
    } else {
      if (ERROR_STATUS.includes(response.status)) {
        let res_err: any = {
          code: response.status,
          status: `${response.status} error`,
        };
        throw handleError(res_err, "session");
      } else {
        let res_err: any = await response.json();
        throw handleError(res_err, "session");
      }
    }
  } catch (error: any) {
    return { success: false, message: error.message };
  }
}

/**
 * @descr This endpoint retrieves all grants for the user with the given email address. Grants are returned in descending order of creation date.
 * @param user user email data
 */
export async function getUserGrants(user: any) {
  try {
    const response = await fetch(
      `${REST_API_URL}/api/hyperchat/handle_users/user_grants?email=${user}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
      }
    );

    // Check response
    if (response.ok) {
      return await response.json();
    } else {
      if (ERROR_STATUS.includes(response.status)) {
        let res_err: any = {
          code: response.status,
          status: `${response.status} error`,
        };
        throw handleError(res_err, "grants");
      } else {
        let res_err: any = await response.json();
        throw handleError(res_err, "grants");
      }
    }
  } catch (error: any) {
    return error.response;
  }
}

/**
 * @desc this function retrieve the namespaces metadata based passased as an argument
 * @arg {Object<any>} namespace_data namespaces data
 */
export async function getNamespacesMetadata(namespace_data: any) {
  try {
    const response = await fetch(
      `${REST_API_URL}/api/hyperchat/handle_namespaces/get_namespaces_metadata`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
        body: JSON.stringify(namespace_data),
      }
    );

    // Check response
    if (response.ok) {
      return await response.json();
    } else {
      if (ERROR_STATUS.includes(response.status)) {
        let res_err: any = {
          code: response.status,
          status: `${response.status} error`,
        };
        throw handleError(res_err, "metadata");
      } else {
        let res_err: any = await response.json();
        throw handleError(res_err, "metadata");
      }
    }
  } catch (error: any) {
    return error.response;
  }
}

/**
 * @desc this function retrieve the namespaces data based on the user role
 * @return array of objects
 */
export async function getNamespacesData(data: any) {
  try {
    const response = await fetch(
      `${REACT_APP_REST_API_URL}/api/hyperchat/handle_namespaces/list_namespaces`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
        body: JSON.stringify(data),
      }
    );

    // Check response
    if (response.ok) {
      return await response.json();
    } else {
      if (ERROR_STATUS.includes(response.status)) {
        let res_err: any = {
          code: response.status,
          status: `${response.status} error`,
        };
        throw handleError(res_err, "retrieve");
      } else {
        let res_err: any = await response.json();
        throw handleError(res_err, "retrieve");
      }
    }
  } catch (error: any) {
    return error.response;
  }
}

/**
 * @desc this function allows you to delete all chats owned by the user
 * @param {Object} data user owner email
 */
export async function deleteAllChat(data: any) {
  try {
    const response = await fetch(
      `${REST_API_URL}/api/hyperchat/chat/private/delete_user_chats`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
        method: "DELETE",
        body: JSON.stringify(data),
      }
    );

    // Check response
    if (response.ok) {
      return await response.json();
    } else {
      if (ERROR_STATUS.includes(response.status)) {
        let res_err: any = {
          code: response.status,
          status: `${response.status} error`,
        };
        throw handleError(res_err, "delete");
      } else {
        let res_err: any = await response.json();
        throw handleError(res_err, "delete");
      }
    }
  } catch (error: any) {
    return error.response;
  }
}

/**
 * @returns
 */
export async function getUserInfo() {
  try {
    const response = await fetch(
      `${REST_API_URL}/api/hyperchat/handle_users/user_info`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
      }
    );

    // Check response
    if (response.ok) {
      return await response.json();
    } else {
      if (ERROR_STATUS.includes(response.status)) {
        let res_err: any = {
          code: response.status,
          status: `${response.status} error`,
        };
        throw handleError(res_err, "retrieve");
      } else {
        let res_err: any = await response.json();
        throw handleError(res_err, "retrieve");
      }
    }
  } catch (error: any) {
    return error.response;
  }
}
