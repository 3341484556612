import { useCallback } from "react";
import { t } from "i18next";
import { getCookie } from "../core/services";
import { ID } from "../config";
import { REST_API_URL } from "../custom.config";
import { ChatData, ChatList } from "../data-structures/ChatType";
import { Toast } from "../core/components/layout";
import { HcError } from "./ApiErrors";

type ChatApi = {
  getChats: () => Promise<ChatList>;
  deleteChat: (chat_id: string, showTost?: boolean) => Promise<number>;
  deleteAllChats: () => Promise<number>;
  renameChat: (chat_id: string, new_title: string) => Promise<void>;
  downloadChat: (chat_id: string, file_name: string) => Promise<void>;
  generateChatTitle: (chat_id: string, message: string) => Promise<number>;
  addNewChat: (namespace_id: string) => Promise<{ chat_id: string }>;
  getChatHistory: (chat_id: string, messageId?: string) => Promise<ChatData>;
  sendUserMessage: (chat_id: string, message: string) => Promise<any>;
  stopStreaming: (chatId: string, messageId: string) => Promise<void>;
};

export default function useChatApi(): ChatApi {
  const getChats = useCallback(async () => {
    const response = await fetch(
      `${REST_API_URL}/api/hyperchat/chat/list_chats`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
      }
    );

    //Check response
    if (response.ok) {
      return await response.json();
    } else {
      let err = await response.json();
      console.log(err);
      Toast("error", t(`errors.chat.retrieve.${err.code}`));
      return { chats: [] };
    }
  }, []);
  const deleteChat = useCallback(
    async (chat_id: string, showTost: boolean = true) => {
      const response = await fetch(
        `${REST_API_URL}/api/hyperchat/chat/delete_chat`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
          },
          method: "DELETE",
          body: JSON.stringify({ chat_id: chat_id }),
        }
      );

      //Check response
      if (response.ok) {
        showTost && Toast("success", t("errors.chat.delete.200"));
        return response.status;
      } else {
        let err = await response.json();
        Toast("error", t(`errors.chat.delete.${err.code}`));
        return err.code;
      }
    },
    []
  );

  const deleteAllChats = useCallback(async () => {
    const response = await fetch(
      `${REST_API_URL}/api/hyperchat/chat/delete_user_chats`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
        method: "DELETE",
      }
    );

    //Check response
    if (response.ok) {
      Toast("success", t("errors.chat.delete.200"));
      return response.status;
    } else {
      let err = await response.json();
      console.log(err);
      Toast("error", t(`errors.chat.delete.${err.code}`));
      return err.code;
    }
  }, []);

  const renameChat = useCallback(async (chat_id: string, new_title: string) => {
    const response = await fetch(
      `${REST_API_URL}/api/hyperchat/chat/rename_chat`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
        method: "PUT",
        body: JSON.stringify({ chat_id: chat_id, new_title: new_title }),
      }
    );

    //Check response
    if (response.ok) {
      Toast("success", t("errors.chat.rename.200"));
      return response.status;
    } else {
      let err = await response.json();
      console.log(err);
      Toast("error", t(`errors.chat.rename.${err.code}`));
      return err.code;
    }
  }, []);

  const downloadChat = useCallback(
    async (chat_id: string, file_name: string): Promise<void> => {
      const response = await fetch(
        `${REST_API_URL}/api/hyperchat/chat/download_chat?chat_id=${chat_id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
          },
        }
      );
      const resp = await response.blob();
      const href = window.URL.createObjectURL(resp);
      const anchorElement = document.createElement("a");
      anchorElement.href = href;
      anchorElement.download = file_name;
      document.body.appendChild(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    },
    []
  );

  const generateChatTitle = useCallback(
    async (chat_id: string, message: string) => {
      const response = await fetch(
        `${REST_API_URL}/api/hyperchat/chat/generate_chat_title`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
          },
          method: "POST",
          body: JSON.stringify({ chat_id: chat_id, text: message }),
        }
      );

      //Check response
      if (response.ok) {
        return response.status;
      } else {
        let err = await response.json();
        console.log(err);
        Toast("error", t(`errors.chat.rename.${err.code}`));
        return err.code;
      }
    },
    []
  );

  const addNewChat = useCallback(async (namespace_id: string) => {
    const response = await fetch(
      `${REST_API_URL}/api/hyperchat/chat/create_chat`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
        method: "POST",
        body: JSON.stringify({ namespace_id: namespace_id }),
      }
    );

    // Check response
    if (response.ok) {
      return await response.json();
    } else {
      let err = await response.json();
      console.log(err);
      Toast("error", t(`errors.chat.new.${err.code}`));
      return err.code;
    }
  }, []);

  const getChatHistory = useCallback(
    async (chat_id: string, messageId: string | undefined) => {
      let requestUrl = `${REST_API_URL}/api/hyperchat/chat/chat_history?chat_id=${chat_id}`;
      if (messageId) requestUrl += `&message_id=${messageId}`;
      const response = await fetch(requestUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
        },
      });

      // Check response
      if (response.ok) {
        return await response.json();
      } else {
        let err = new HcError(await response.json());
        console.log(err);
        Toast("error", t(`errors.chat.history.${err.response.code}`));
        return err;
      }
    },
    []
  );

  const sendUserMessage = useCallback(
    async (chat_id: string, message: string) => {
      const response = await fetch(
        `${REST_API_URL}/api/hyperchat/chat/add_message`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
          },
          body: JSON.stringify({ chat_id: chat_id, message: message }),
        }
      );

      // Check response
      if (response.ok) {
        return response.body;
      } else {
        let err = await response.json();
        ![412, 500].includes(err?.code) &&
          Toast("error", t(`errors.chat.history.${err.code}`));
        return err.code;
      }
    },
    []
  );

  const stopStreaming = useCallback(
    async (chatId: string, messageId: string) => {
      const response = await fetch(
        `${REST_API_URL}/api/hyperchat/chat/stop_message_stream`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie(`token_${ID}`)}`,
          },
          body: JSON.stringify({ chat_id: chatId, message_id: messageId }),
        }
      );

      // Check response
      if (response.ok) {
        return await response.json();
      } else {
        let err = await response.json();
        console.log(err);
        Toast("error", t(`errors.chat.history.${err.code}`));
        return err.code;
      }
    },
    []
  );

  return {
    getChats,
    deleteChat,
    deleteAllChats,
    renameChat,
    downloadChat,
    generateChatTitle,
    addNewChat,
    getChatHistory,
    sendUserMessage,
    stopStreaming,
  };
}
