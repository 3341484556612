import { useCallback } from "react";
import { t } from "i18next";
import { REST_API_URL } from "../custom.config";
import { getCookie, saveToStorage } from "../core/services";
import { ID } from "../config";
import { Toast } from "../core/components/layout";
import { Namespace } from "../data-structures/NamespaceType";
import { HcError } from "./ApiErrors";

type NamespaceApi = {
  getNamespaceList: (sessionData?: any) => Promise<string[] | HcError>;
  getNamespaces: (namespaceList: string[]) => Promise<Namespace[]>;
  getNamespaceListByRole: (role: string) => Promise<string[] | HcError>;
};

export default function useNamespaceApi(): NamespaceApi {
  const getNamespaceListByRole = useCallback(async (role: string) => {
    const response = await fetch(`${REST_API_URL}/api/hyperchat/handle_namespaces/list_namespaces`, {
      method: 'POST',
      headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
      },
      body: JSON.stringify({role: role})
    });
    if (response.ok) {
      let resp = await response.json();
      return resp.namespaces;
    } else {
      console.log(response);
      let err = new HcError({
        code: response.status,
        message: response.statusText,
        status: response.status.toString()
      });
      Toast("error", t(`errors.documents.retrieve.${response.status}`));
      return err;
    }
  }, []);

  const getNamespaceList = useCallback(async (sessionData: any = undefined) => {
    let roles = ["user", "supervisor", "admin"];
    let namespaceList: string[] = [];
    for (let role of roles) {
      const response = await fetch(`${REST_API_URL}/api/hyperchat/handle_namespaces/list_namespaces`, {
        method: 'POST',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
        },
        body: JSON.stringify({role: role})
      });
      if (response.ok) {
        let resp = await response.json();
        if (resp.namespaces.length > 0) {
          namespaceList = [...namespaceList, ...resp.namespaces];
          if (sessionData !== undefined) {
            //Updating session user data
            let new_session_data:any = {
              ...sessionData,
              role: role,
            };
            saveToStorage(`user_${ID}`, new_session_data);;
          }
        };
      } else {
        console.log(response);
        let err = new HcError({
          code: response.status,
          message: response.statusText,
          status: response.status.toString()
        });
        Toast("error", t(`errors.documents.retrieve.${response.status}`));
        return err;
      }
    }
    return [...Array.from(new Set(namespaceList))];
  }, []);

  const getNamespaces = useCallback(async (namespaceList: string[]) => {
    const response = await fetch(`${REST_API_URL}/api/hyperchat/handle_namespaces/get_namespaces_metadata`, {
      method: 'POST',
      headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${getCookie(`token_${ID}`)}`
      },
      body: JSON.stringify({namespaces: namespaceList})
    });

    // Check response
    if (response.ok) {
        return await response.json();
    } else {
      let err = await response.json();
      console.log(err);
      Toast("error", t(`errors.documents.retrieve.${err.code}`));
    }
  }, []);

  return {
    getNamespaceList,
    getNamespaces,
    getNamespaceListByRole,
  };
}